.scheduleWrap {
    padding: 60px 48px 60px 48px;
    box-sizing: border-box;
    background-color: #F5F8FF;
    min-height: calc(100vh - 72px);
    min-width: calc(7 * (142px) + 96px + 142px);
    position: relative;
}

.utilityRowWrap {
  box-sizing: border-box;
  padding-bottom: 34px;
  border-bottom: 2px solid #707070;
}

.utilityRowWrap, .utilityRowWrap .left, .utilityRowWrap .right, .changeDateBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.utilityRowWrap .left {
  gap: 24px;
}

.utilityRowWrap .right {
  gap: 20px;
}

.teacherSelect {
  width: 250px;
}

.teacherSelect.disabled {
  display: none;
}

.modeToggle {
  display: flex;
  align-items: center;
}

.modeToggle .toggle {
  width: 114px;
  height: 60px;
  background-color: #FFFFFF;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font: normal normal normal 24px/29px InterRegular;
  user-select: none;
}

.modeToggle .Week {
  border-radius: 8px 0px 0px 8px;
}

.modeToggle .Month {
  border-radius: 0px 8px 8px 0px;
}

.toggle.active {
  background-color: #1452CC;
  color: #FFFFFF;
}

.changeDateBtn .arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 60px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 8px;
  cursor: pointer;
}

.changeDateBtn .date {
  font: normal normal 600 24px/29px InterRegular;
  text-decoration: underline;
  text-align: center;
  width: 300px;
  user-select: none;
  position: relative;
}

