.addClassModalWrap {
  width: 60vw;
  height: 750px;
  min-width: 1320px;
  background-color: #fff;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 60px;
  display: flex;
  flex-direction: column;
}

.modalTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font: normal normal 600 24px/29px InterRegular;
}

.cancel {
  cursor: pointer;
  transform: scale(1.5);
}

.firstRow {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.firstRow .courseName {
  flex: 2;
}

.firstRow .teacherName {
  flex: 1;
}

.firstRow .classroom {
  flex: 1;
}

.secondRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  margin-top: 20px;
}

.secondRow .left {
  display: flex;
  align-items: center;
  gap: 24px;
  flex: 2;
}

.secondRow .left .timePickerWrap {
  flex: 1;
}

.secondRow .timePickerWrap {
  flex: 1;
}

.thirdRow {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-right: 45px;
}

.thirdRow .left {
  display: flex;
  gap: 24px;
}

.thirdRow .left .frequency {
  width: 284px;
}

.weekdayWrap {
  display: flex;
  gap: 24px;
}

.weekdayWrap.disabled .weekDayBtn {
  background-color: #fff;
  border: 1px solid #e3e3e3;
  color: #e3e3e3;
  pointer-events: none;
}

.block {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font: normal normal 600 24px/30px InterLight;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
}

.weekDayBtn {
  border: 1px solid #b3b3b3;
  font: normal normal normal 14px/17px InterRegular;
}

.weekDayBtn.active {
  background-color: #1452cc;
  color: #fff;
}

.addBtn {
  background-color: #1452cc;
  color: #fff;
}

.addBtn.disabled {
  background-color: #e3e3e3;
  cursor: not-allowed;
}

.timStrWrap {
  width: 120px;
  font: normal normal normal 14px/17px InterRegular;
  display: flex;
  align-items: center;
}

.timStrWrap.disabled {
  visibility: hidden;
  pointer-events: none;
}

.listWrap {
  width: 100%;
  height: 290px;
  box-sizing: border-box;
  padding: 31px 34px;
  background-color: #f5f8ff;
  border: 1px solid #707070;
  border-radius: 16px;
  margin-top: 20px;
  overflow-y: scroll;
}

.listWrap::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.tableWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  min-height: 48px;
}

.tableWrap.tableContent {
  margin-top: 20px;
}

.tableWrap .left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  height: 100%;
}

.tableItem {
  height: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-left: 0.5%;
  font: normal normal 600 14px/17px InterRegular;
}

.icon {
  width: 48px;
  height: 48px;
  min-width: 48px;
  max-width: 48px;
}

.left .courseName {
  width: calc(100% / 20 * 5);
  word-break: break-all;
}

.left .teacherName,
.left .frequency,
.left .classroom {
  width: calc(100% / 20 * 3);
}

.left .date,
.left .stim,
.left .etim {
  width: calc(100% / 20 * 2);
}

.tableContent .left {
  background-color: #fff;
  border-radius: 8px;
}

.tableWrap.tableHeader .left .tableItem {
  background-color: #fff;
  border-radius: 8px;
}

.tableWrap.tableHeader .icon {
  visibility: hidden;
  pointer-events: none;
}

.tableWrap.tableContent .icon {
  cursor: pointer;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.tableWrap.tableContent.disabled .icon {
  background-color: transparent;
}

.tableWrap.tableContent.disabled .left {
  background-color: #e3e3e3;
}

.createWrap {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.createBtn {
  width: 162px;
  height: 48px;
  border-radius: 8px;
  cursor: pointer;
  background-color: #1452cc;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal normal 14px/17px InterRegular;
}

.createBtn.disabled {
  background-color: #e3e3e3;
  cursor: not-allowed;
}

.loadingWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #0000008a;
  z-index: 10;
}

.upperWrap {
  display: flex;
  align-items: center;
  margin-top: 20px;
  gap: 24px;
}

.upperLeft {
  width: calc(100% - 310px);
}

.upperRight {
  width: 310px;
  min-width: 310px;
  height: 140px;
}

.upperRight img {
  margin-top: 10px;
  height: 120px;
}
