.importAssignmentWrap {
  background-color: #f5f8ff;
  box-sizing: border-box;
  font-size: 100%;
  min-height: calc(100vh - 72px);
  min-width: calc(100vw - 240px);
  padding: 62px 52px;
}

.breadCrumbs {
  display: flex;
  gap: 24px;
  align-items: center;
  margin-bottom: 1.5rem;
}

.pointer {
  cursor: pointer;
}

.breadCrumbs .tab {
  font: normal normal 600 24px/29px InterRegular;
  font-size: clamp(1.4rem, 0.4em + 0.8vw, 1.6rem);
}

.importContent {
  width: 50%;
  background-color: #fff;
  height: 400px;
  margin-bottom: 24px;
  padding: 36px;
  box-sizing: border-box;
  border-radius: 8px;
}

.importHistory {
  background-color: #fff;
  min-height: 352px;
  margin-bottom: 24px;
  padding: 36px;
  box-sizing: border-box;
  border-radius: 8px;
}

.title {
  font: normal normal 600 24px/29px InterRegular;
  font-size: clamp(1.4rem, 0.4em + 1vw, 1.6rem);
}

.step1 {
  margin: 2rem 0rem;
}

.step1 > div {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 3fr;
}

.sourceWrap {
  font: normal normal 600 18px/21px InterRegular;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
}

.noticeWrap {
  margin-top: 1rem;
  color: #808080;
}

.noticeWrap > div {
  display: flex;
}

.noticeWrap img {
  margin-right: 0.5rem;
}

.step2 {
  font: normal normal 600 18px/21px InterRegular;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: center;
  margin: 2rem 0rem 3rem 0rem;
}

.step2 > p {
  padding-top: 20px;
}

.inputFile[type='file'] {
  display: none;
}

.uploadFileBtn {
  width: 162px;
  border-radius: 8px;
  margin-right: 1.5rem;
  cursor: pointer;
  background-color: #1452cc;
  color: #fff;
  font: normal normal normal 14px/17px InterRegular;
  border: none;
  text-align: center;
  padding: 0.5rem 0rem;
}

.uploadFileBtn:hover {
  background-color: #175CE6;
}

.fileWrap,
.noticeWrap {
  display: flex;
  align-items: center;
}

.btnArea {
  display: flex;
}

.uploadBtn {
  width: 162px;
  height: 48px;
  border-radius: 8px;
  margin-right: 1.5rem;
  cursor: pointer;
  background-color: #1452cc;
  color: #fff;
  font: normal normal normal 14px/17px InterRegular;
  border: none;
}

.uploadBtn:hover {
  background-color: #175CE6;
}

.uploadBtn:active {
  background-color: #1142A6;
}

.uploadBtn > div.uploading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.uploadBtn.disabled, .uploadBtn.disabled:hover, .uploadBtn.disabled:active {
  background-color: #e3e3e3;
  cursor: not-allowed;
}

.cancelBtn {
  width: 162px;
  height: 48px;
  border-radius: 8px;
  cursor: pointer;
  background-color: #fff;
  color: #1452cc;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #1452cc;
  font: normal normal normal 14px/17px InterRegular;
  margin-right: 2rem;
}

.cancelBtn:hover {
  border: 2px solid #175CE6;
  background-color: #175CE6;
  color: #fff;
}

.cancelBtn:active {
  border: 2px solid #1142A6;
  background-color: #1142A6;
  color: #fff;
}

.cancelBtn.disabled, .cancelBtn.disabled:hover, .cancelBtn.disabled:active {
  color: #e3e3e3;
  background-color: #fff;
  border: 2px solid #e3e3e3;
  cursor: not-allowed;
}

.titleWrap {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  gap: 20px;
  width: 100%;
  padding-bottom: 0.5rem;
  margin-top: 1.5rem;
  border-bottom: 1.5px solid #e3e3e3;
}

.tableItem {
  height: 100%;
  font: normal normal 600 18px/21px InterRegular;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0.5rem 0 0.5rem 1.2rem;
}

.updatedDate img {
  margin-left: 1rem;
  cursor: pointer;
}

.itemWrap {
  margin-top: 20px;
  background-color: #fff;
  display: grid;
  font: normal normal 600 18px/21px InterRegular;
  font-size: 16px;
  grid-template-columns: 2fr 2fr 1fr 1fr;
  gap: 20px;
  height: 48px;
  border-radius: 8px;
}

.pointer {
  cursor: pointer;
}

.item {
  border-radius: 8px;
  height: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0.5rem 0 0.5rem 1.2rem;
  min-width: 0;
}

.item > p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.item.status > p {
  color: #fff;
  padding: 0.5rem;
  background-color: #009135;
  border-radius: 4px;
}

.launchTool {
  width: 300px;
  height: 80px;
  border-radius: 10px;
  background-color: rgb(71, 105, 255);
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

@media screen and (max-width: 1500px) {
  .importContent {
    width: 65%;
  }
}
