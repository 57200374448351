.importCanvasWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 181px;
  height: 60px;
  border-radius: 8px;
  box-shadow: 0px 2px 2px 1px rgb(0, 0, 0, 0.2);
  background-color: #fff;
  cursor: pointer;
}

.importCanvasWrap img {
  width: 154px;
  height: 48px;
}