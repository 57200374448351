.utilityRowWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
}

.utilityRowWrap .right {
  display: flex;
  gap: 24px;
}

.searchInputWrap {
  width: 323px;
}

.addQuizBtn {
  width: 181px;
  height: 60px;
  background-color: #175ce6;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
  border-radius: 8px;
  font: normal normal 600 18px/21px InterLight;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
  border: none;
}

.titleWrap {
  display: grid;
  grid-template-columns: 2.8fr 1.8fr 1fr 1fr 0.7fr;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  gap: 20px;
  width: 100%;
  min-width: 1350px;
}

.tableItem {
  border-radius: 8px;
  height: 100%;
  background-color: #fff;
  font: normal normal 600 18px/21px InterRegular;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0.5rem 0 0.5rem 1.2rem;
}

.noItems {
  background-color: #fff;
  text-align: center;
  padding: 3rem;
  margin-top: 1rem;
  border-radius: 8px;
}

.noItems p {
  color: #808080;
}

p {
  margin: 0;
}

.itemWrap {
  margin-top: 20px;
  background-color: #fff;
  display: grid;
  font: normal normal 600 18px/21px InterRegular;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
  grid-template-columns: 2.8fr 1.8fr 1fr 1fr 0.7fr;
  gap: 20px;
  height: 48px;
  min-width: 1350px;
  border-radius: 8px;
}

.item {
  border-radius: 8px;
  background-color: #fff;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
  box-sizing: border-box;
  padding: 0.5rem 0rem 0.5rem 1.2rem;
  display: flex;
  align-items: center;
  min-width: 0;
}

.item.quizName {
  cursor: pointer;
}

.item > p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.courseWrap {
  display: grid;
  grid-template-columns: 3fr 3fr 1.1fr;
  gap: 10px;
}

.course {
  background-color: #e8e8e8;
  padding: 0 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  font: normal normal 600 14px/21px InterRegular;
  font-size: clamp(0.8rem, 0.2em + 0.5vw, 1rem);
  min-width: 38%;
}

.course p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.menuOption {
  width: 169px;
  height: 48px;
  cursor: pointer;
  font: normal normal 600 14px/17px InterRegular;
  font-size: clamp(0.8rem, 0.3rem + 0.4vw, 1.3rem);
  display: flex;
  align-items: center;
  padding-left: 12px;
}

.menuOption:hover {
  background-color: #f3f3f3;
  color: #1452cc;
}

.paginationWrap {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.searchInputWrap {
  width: 323px;
}

.tableContainer {
  box-sizing: border-box;
  height: calc(100vh - 23px);
  overflow-x: scroll;
}

.more {
  padding: 0;
}

.question {
  display: flex;
  align-items: center;
}

.question img {
  margin-right: 0.5rem;
}

.updatedDate img {
  margin-left: 1rem;
  cursor: pointer;
}