.participants {
  max-height: calc(85vh - 200px);
  overflow: scroll;
}

.participants.scroll_disabled {
  overflow: hidden;
}

.noResults {
  background-color: #fff;
  height: 270px;
  padding-top: 2rem;
}

.noResults > p {
  font: normal normal 400 18px/32px InterRegular;
  color: #808080;
  text-align: center;
}

.participant {
  border-radius: 8px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 34px 45px;
  margin-bottom: 29px;
  background-color: #fff;
}

.participant.open {
  height: 435px;
}

.arrow {
  width: 8px;
  cursor: pointer;
}

.info {
  display: flex;
  gap: 10px;
  align-items: center;
}

.name {
  font: normal normal 600 18px/21px InterRegular;
}

.accuracyNum {
  font: normal normal 600 18px/21px InterRegular;
}

.accuracyTxt {
  font: normal normal normal 14px/17px InterRegular;
}

.box {
  padding: 6px 12px;
  border-radius: 4px;
}

.correct {
  border: 1px solid #009135;
  background-color: #6ce59833;
  color: #009135;
}

.incorrect {
  border: 1px solid #e60026;
  background-color: #e6a1aa33;
  color: #e60026;
}

.tableWrap {
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.tableWrap.tableContent {
  align-items: flex-start;
  margin-bottom: 24px;
}

.tableHeader {
  height: 45px;
  padding: 13px 21px;
  box-sizing: border-box;
}

.tableContent {
  min-height: 30px;
  padding: 13px 21px;
  border-radius: 6px;
  background-color: #ebedf099;
}

.tableItem {
  font: normal normal 600 18px/21px InterRegular;
}

.tableItem.question {
  width: calc(100% / 4 * 2);
}

.tableItem.participant_answer,
.tableItem.correct_answer {
  width: calc(100% / 4);
}

.tableContent .tableItem.question {
  display: flex;
  gap: 20px;
  font: normal normal normal 18px/21px InterMedium;
}

.right {
  color: #009135;
}

.wrong {
  color: #e60026;
}

.img {
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../../../assets/icons/image.svg');
}

.img:hover {
  background-image: url('../../../../assets/icons/image_hover.svg');
}

.img.disabled {
  background-image: url('../../../../assets/icons/image_disabled.svg');
  pointer-events: none;
}
