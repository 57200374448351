.weekDayTextWrap {
  display: flex;
}

.weekDayText {
  /* width: 184px; */
  width: calc(100% / 7);
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* font: normal normal normal 24px/30px InterMedium; */
  font: normal normal 600 18px/21px InterMedium;
}