.modalWrap {
  width: 724px;
  height: 294px;
  background-color: #fff;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 45px;
}

.titleWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.titleWrap .title {
  font: normal normal 600 24px/29px InterMedium;
}

.titleWrap .cancel {
  cursor: pointer;
  transform: scale(1.6);
}

.btnWrap {
  display: flex;
  justify-content: right;
}

.createBtn {
  width: 162px;
  height: 48px;
  border-radius: 8px;
  cursor: pointer;
  background-color: #1452CC;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal normal 14px/17px InterRegular;
  border: none
}

.createBtn:hover {
  background-color: #175CE6;
}

.createBtn:active {
  background-color: #1142A6;
}

.inputWrap {
  margin: 1.2rem 0rem 2rem 0rem;
  position: relative
}

.createBtn.disabled, .createBtn.disabled:hover, .createBtn.disabled:active {
  background-color: #E3E3E3;
  cursor: not-allowed;
}

.lengthHint {
  color: #E3E3E3;
  position: absolute;
  top: 16px;
  right: 13px;
}