.breadCrumb {
  display: flex;
  align-items: center;
  gap: 26px;
}

.home {
  cursor: pointer;
  background-image: url('../../assets/icons/breadcrumbHome.svg');
  background-position: center;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
}

.home:hover {
  background-image: url('../../assets/icons/breadcrumbHome_hover.svg');
}
