.modalWrap {
  width: 724px;
  height: 294px;
  background-color: #fff;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 60px;
}

.titleWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.titleWrap .title {
  font: normal normal 600 24px/29px InterMedium;
}

.titleWrap .cancel {
  cursor: pointer;
  transform: scale(1.6);
}

.btnWrap {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
}

.btnWrap button {
  border: none;
  background-color: #ebedf080;
  width: calc(100% / 3 - 2rem);
  height: 8rem;
  font: normal normal 600 16px/18px InterRegular;
  border-radius: 8px;
  cursor: pointer;
  padding-bottom: 1rem;
}

.normal img {
  width: 40px;
  display: block;
  margin: 1rem auto;
}

.hover img {
  width: 40px;
  display: block;
  margin: 1rem auto;
}

.hover p {
  color: #1452cc;
}
