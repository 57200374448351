.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F5F8FF;
  position: relative;
}

.loginWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 44px;
  width: 560px;
  height: calc(100vh - 72px);
}

.title {
  font: normal normal 600 36px/44px InterRegular;
  font-size: clamp(2rem, 0.4em + 1vw, 2.5rem);

  align-self: flex-start;
}

.email, .password, .submit {
  width: 100%;
  height: 48px;
}

.hint {
  font: normal normal normal 18px/30px InterRegular;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
  cursor: pointer;
}

.errorHint {
  font: normal normal normal 18px/20px InterRegular;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
  color: red;
}

.submit {
  background-color: #175CE6;
  border-radius: 8px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font: normal normal normal 18px/21px InterRegular;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
  cursor: pointer;
  border: none;
}

.orWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.or {
  color: #b6b6b6;
  font: normal normal normal 18px/21px InterRegular;
}

.divideLine {
  width: 100%;
  border: 1px solid #d1d1d1;
}

.googleSubmit {
  background-color: #fff;
  border-radius: 8px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  font: normal normal normal 18px/21px InterRegular;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
  cursor: pointer;
  border: none;
  gap: 20px;
  width: 100%;
  height: 48px;
}

.submit:active {
  background-color: #1452CC;
}

.spinnerWrap {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(13, 13, 13, 0.5);
}