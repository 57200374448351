.StudentListWrap.hide {
  display: none;
}

.searchInputWrap {
  width: 323px;
  margin-bottom: 20px;
}

.tableContainer {
  overflow-x: scroll;
  height: calc(100vh - 180px);
}

.tableWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  height: 48px;
  min-width: 1200px;
}

.tableWrap.tableContent {
  background-color: #fff;
  border-radius: 8px;
  margin-top: 20px;
}

.tableItem {
  background: #fff;
  border-radius: 8px;
  height: 100%;
  display: flex;
  align-items: center;
  font: normal normal 600 18px/21px InterRegular;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
  box-sizing: border-box;
  padding-left: 0.5%;
}

.tableItem.name, .tableItem.displayName {
  width: calc(100% / 14 * 2);
}

.tableItem.email {
  width: calc(100% / 14 * 3);
}

.tableItem.course {
  width: calc(100% / 14 * 5);
}

.tableItem.status {
  width: calc(100% / 14 * 1);
}

.tableWrap.center .status {
  padding-left: 0;
  justify-content: center;
}

.pending {
  width: 76px;
  height: 30px;
  background-color: #1452CC;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font: normal normal 600 14px/18px InterLight;
  font-size: clamp(0.8rem, 0.2em + 0.5vw, 1rem);
}

.courseBlockContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 30px;
  box-sizing: border-box;
  padding: 0 8px;
  background-color: #E8E8E8;
  border-radius: 8px;
  font: normal normal 600 14px/18px InterRegular;
  font-size: clamp(0.8rem, 0.2em + 0.5vw, 1rem);
  margin-right: 12px;
}

.leftCourse {
  background-color: #E8E8E8;
  border-radius: 8px;
  padding: 7px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal 600 14px/18px InterRegular;
  font-size: clamp(0.8rem, 0.2em + 0.5vw, 1rem);
  cursor: pointer;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.paginationWrap {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.messageBox {
  background-color: #fff;
  width: 100%;
  height: 112px;
  border-radius: 8px;
  font: normal normal normal 24px/29px InterRegular;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
  color: #888888;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}