.breadCrumbs {
  display: flex;
  justify-content: space-between;
}

.breadCrumbs > div {
  display: flex;
  gap: 24px;
  align-items: center;
  margin-bottom: 1.5rem;
}

.pointer {
  cursor: pointer;
}

.breadCrumbs > div .tab {
  font: normal normal 600 24px/29px InterRegular;
  font-size: clamp(1.4rem, 0.4em + 0.8vw, 1.6rem);
}

.backBtn {
  margin-left: auto;
  width: 162px;
  height: 48px;
  border-radius: 8px;
  cursor: pointer;
  background-color: #f5f8ff;
  color: #1452cc;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #1452cc;
  font: normal normal 600 18px/21px InterRegular;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
}

.backBtn > img,
.backBtn > p {
  margin-right: 0.5rem;
}

.contentWrap {
  display: flex;
  min-height: 45rem;
}

.left {
  background-color: #fff;
  border-radius: 8px;
  width: 65%;
  margin-right: 1.5rem;
  padding: 36px;
  box-sizing: border-box;
}

.right {
  width: 35%;
}

.saveBtn {
  width: 256px;
  height: 48px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal normal 14px/17px InterRegular;
  box-sizing: border-box;
}

.saveBtn.disabled {
  cursor: not-allowed;
}

.saveBtn.disabled > span {
  margin-left: 0.5rem;
}

.saveBtn.disabled.blue {
  border: none;
  background-color: #DCDEE0;
  color: #fff;
}

.saveBtn.disabled.none {
  border: 2px solid #DCDEE0;
  color: #DCDEE0;
}

.saveBtn.blue {
  border: 2px solid transparent;
  background-color: #1452cc;
  color: #fff;
}

.saveBtn.none {
  border: 2px solid #1452cc;
  background-color: transparent;
  color: #1452cc;
}


.noItems {
  background-color: #fff;
  text-align: center;
  padding: 3rem;
  margin-top: 1rem;
  border-radius: 8px;
}

.noItems p {
  color: #808080;
}

p {
  margin: 0;
}

.information {
  background-color: #fff;
  padding: 28px;
  box-sizing: border-box;
  border-radius: 8px 8px 0px 0px;
}

.information > h1 {
  font: normal normal 600 18px/21px InterRegular;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
  margin: 1rem 0rem;
  padding-left: 8px;
}

.information .title {
  padding-left: 8px;
}

.information .statusWrap {
  display: flex;
  font: normal normal 600 18px/21px InterRegular;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
  margin: 1rem 0rem;
  padding-left: 8px;
}

.information > p {
  font: normal normal normal 18px/21px InterRegular;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
  margin: 1rem 0rem;
  padding-left: 8px;
}

.information .questionIcon {
  width: 1.3rem;
  height: 1.3rem;
  background-color: #000;
  border-radius: 50%;
  margin-left: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.information .disabledImg {
  margin-left: 0.6rem;
}

.duration {
  border-radius: 0px 0px 8px 8px;
  height: 258px;
  background-color: #EBEDF0;
  font: normal normal 600 14px/17px InterRegular;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.subTitle {
  margin-bottom: 12px;
}

.assignedCourse {
  background-color: #fff;
  min-height: calc((100% - 24px) / 2);
  padding: 36px;
  box-sizing: border-box;
  border-radius: 8px;
}

.btnWrap {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  gap: 42px;
}

.titleWrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  height: 48px;
}

.titleWrap button {
  width: 162px;
  height: 48px;
  border-radius: 8px;
  cursor: pointer;
  background-color: #fff;
  color: #1452cc;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #1452cc;
  font: normal normal 600 18px/21px InterRegular;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
}

.titleWrap button:hover {
  background-color: #175CE6;
  border-color: #175CE6;
  color: #fff;
}

.titleWrap button:active {
  background-color: #1142A6;
  border-color: #1142A6;
  color: #fff;
}

.titleWrap button.disabled {
  border: none;
  color: #fff;
  background-color: #e3e3e3;
  cursor: not-allowed;
}

.title {
  font: normal normal 600 24px/29px InterRegular;
  font-size: clamp(1.4rem, 0.4em + 1vw, 1.6rem);
}

.tableContainer {
  margin-left: 8px;
  width: 100%;
}

.tableWrap {
  display: grid;
  grid-template-columns: 0.8fr 2fr 0.5fr 0.5fr;
  align-items: center;
  gap: 5px;
  box-sizing: border-box;
  padding: 14px;
  min-height: 55px;
  border-bottom: 1px solid #e3e3e3;
}

.tableWrap.disabled {
  grid-template-columns: 1fr 2.5fr 0.5fr;
}

.itemWrap {
  display: grid;
  grid-template-columns: 0.8fr 2fr 0.5fr 0.5fr;
  align-items: center;
  gap: 5px;
  box-sizing: border-box;
  padding: 2px 14px;
}

.itemWrap.disabled {
  grid-template-columns: 1fr 2.5fr 0.5fr;
}

.itemWrap .question {
  padding-right: 0.5rem;
  max-height: 2.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.itemWrap .question span:nth-child(2) {
  margin: 0rem 0.2rem;
}

.itemWrap .question span:nth-child(3) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.itemWrap .question .imgIcon {
  width: 2rem;
  height: 2rem;
  margin-left: 0.5rem;
}

.tableWrap.tableHeader {
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 1;
  font-weight: bold;
  margin-bottom: 1rem;
}

.tableHeader .tableItem {
  box-sizing: border-box;
  font: normal normal 600 18px/21px InterRegular;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
}

.tableContent .action {
  cursor: pointer;
  width: 35px;
  height: 35px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableContent .action:hover, .tableContent .action.active {
  background-color: #E3E3E3;
}


.assignedCourse .title {
  margin-bottom: 2rem;
}

.stackArea {
  margin-top: 1rem;
}

.timerRow {
  display: flex;
  align-items: center;
}

.timerRow img {
  margin-right: 0.5rem;
}

.timerRow img.disabled {
  margin: 0.7rem;
}

.pointer {
  cursor: pointer;
}

.timerRow p {
  font: normal normal 600 18px/21px InterRegular;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
  margin: 1rem 1rem 1rem 0rem;
}

.timerRow .text {
  margin: 0rem 1rem;
}

.inputWrap {
  margin-top: 1.2rem;
  padding-left: 8px;
  position: relative;
}

.inputWrap p {
  color: #e60026;
  margin-top: 0.5rem;
  font: normal normal normal 14px/20px InterRegular;
}

.lengthHint {
  color: #e3e3e3;
  position: absolute;
  top: 16px;
  right: 13px;
}

.options {
  display: flex;
  align-items: center;
}

.options img {
  margin-right: 0.5rem;
}

.questionType {
  display: flex;
  align-items: center;
}

.questionType img {
  width: 1.8rem;
  margin: 0.5rem 0.8rem 0.5rem 0rem;
}

.selectFilter {
  position: relative;
}

.selectFilter p {
  position: absolute;
  top: 10px;
  left: 5px;
  z-index: 1;
}

.stackArea {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2.5rem;
}

.paginationWrap {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.status {
  background-color: #AFBBC7;
  border-radius: 8px;
  width: 112px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal normal 14px/17px InterRegular;
  color: #fff;
}

@media screen and (max-width: 1500px) {
  .tableWrap,
  .itemWrap {
    grid-template-columns: 1.2fr 2fr 0.5fr 0.3fr;
  }

  .left {
    padding: 28px;
    margin-right: 1rem;
  }

  .information {
    padding: 20px;
  }

  .assignedCourse {
    padding: 28px;
  }
}

