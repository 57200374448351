.wrap {
  display: flex;
  height: 54vh;
  overflow-y: scroll;
}

.wrap::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.weekDayTextWrap {
  display: flex;
}

.weekDayText {
  width: calc(100% - 142px / 7);
  /* width: calc(184px * 1.2); */
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* font: normal normal normal 48px/60px InterMedium; */
  font: normal normal 600 36px/44px InterMedium;
}

.axisLeftWrap, .axisRightWrap {
  height: calc(25 * 100px);
  background-color: #fff;
  border: 2px solid #bebebe;
  box-sizing: border-box;
  border-right: none;
  grid-template-columns: repeat(20, 1fr);
  grid-template-rows: 20px repeat(288, 1fr) 20px;
  display: grid;
}

.axisLeftWrap {
  width: 102px;
  min-width: 102px;
}

.axisRightWrap {
  width: 40px;
  min-width: 40px;
  border-left: none;
}

.axisLeftWrap .axis:first-child {
  margin-bottom: 30px;
}

.axisLeftWrap .axis:last-child {
  margin-top: 30px;
}

.gridWrap {
  /* width: calc(184px * 1.2); */
  width: calc(100% - 142px / 7);
  height: calc(25 * 100px);
  border: 2px solid #bebebe;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(20, 1fr);
  grid-template-rows: 20px repeat(288, 1fr) 20px;
  border-right: none;
  background-color: #fff;
}

.gridWrap:last-child {
  border: 2px solid #bebebe;
}

.grid {
  border: 1px solid rgb(236, 236, 236);
  border-right: none;
  border-left: none;
  margin-bottom: -1px;
}

.axis {
  border-right: none;
  border-left: none;
  margin-bottom: -1px;
  box-sizing: border-box;
  font: normal normal normal 18px/21px InterMedium;
  display: flex;
  align-items: center;
  padding-left: 50px; 
  /* justify-content: flex-end; */
}

.axisLine {
  border-top: 1px solid rgb(236, 236, 236);
}

.singleClass {
  grid-column: 1 / 21;
  grid-row: 2 / 50;
  /* border-left: 3px solid #1452CC; */
  border: 1px solid #1452CC;
  padding-left: 5px;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 4px;
  cursor: pointer;
  overflow: scroll;
  background-color: #fff;
}

.singleClass::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.singleClass.active {
  /* background-color: #e3e3e3; */
  border: 3px solid #1452CC;
}

.duration {
  font: normal normal 600 24px/30px InterLight;
  white-space: nowrap;
}

.course {
  font: normal normal 600 18px/21px InterRegular;
  margin-top: 10px;
  /* white-space: nowrap; */
}

.weekTimelineWrap {
  position: relative;
}

.spinnerWrap {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: calc((184px * 1.2) * 7 + 142px); */
  width: 100%;
  height: 54vh;
  top: 160px;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(180, 180, 180, 0.5);
}

.weekDayTextNum {
  /* width: calc(184px * 1.2); */
  width: calc(100% - 142px / 7);
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* font: normal normal normal 24px/30px InterMedium; */
  font: normal normal 600 18px/21px InterMedium;
}