.bg {
  display: none;
}

.sidebarWrap { 
  width: 240px;
  min-width: 240px;
  min-height: calc(100vh - 72px);
  box-sizing: border-box;
  padding-top: 43px;
  box-shadow: 3px 5px 6px #00000029;
  z-index: 50;
  padding: 18px;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 20px;
  box-sizing: border-box;
  font: normal normal 600 18px/21px InterMedium;
  width: 100%;
  height: 64px;
  cursor: pointer;
  text-decoration: none;
  color: black;
  border-radius: 8px;
}

.tab:hover, .tab.active {
  background-color: #F5F8FF;
}

.tab.active {
  color: #1452CC;
}

@media screen and (max-width: 1200px) {
  .sidebarWrap {
    display: none;
  }

  .sidebarWrap.open {
    display: block;
    width: 240px;
    min-height: 100vh;
    box-sizing: border-box;
    padding-top: 43px;
    box-shadow: 3px 5px 6px #00000029;
    z-index: 100;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #fff;
  }

  .bg.open {
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 50;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    min-width: 100vw;
    min-height: 100vh;
  }
}