.utilityRowWrap {
  display: flex;
  justify-content: space-between;
}

.left {
  display: flex;
  gap: 25px;
  font: normal normal normal 24px/29px InterMedium;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 192px;
  height: 48px;
  border: 2px solid #1452cc;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  background-color: transparent;
  color: #1452cc;
}

.btn:hover {
  background-color: #175CE6;
  color: #fff;
}

.btn:active {
  background-color: #1142A6;
  color: #fff;
}

.btn.disabled {
  border-color: #B3B3B3;
  color: #B3B3B3;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.paginationWrap {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.empty {
  background-color: #fff;
  width: 100%;
  height: 112px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font: normal normal normal 16px/21px Inter;
  color: #808080;
  border-radius: 8px;
  gap: 10px;
  margin-top: 10px;
}