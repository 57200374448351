.wrap {
  width: 596px;
  height: 296px;
  background-color: #fff;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 48px;
  display: flex;
  flex-direction: column;
}

.btnWrap {
  display: flex;
  align-items: center;
  gap: 24px;
  align-self: flex-end;
}

.btn {
  width: 144px;
  height: 48px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  font: normal normal normal 14px/17px InterRegular;
  background-color: #fff;
  margin-top: 83px;
}

.delete {
  border: 1px solid #F10000;
  color: #F10000;
}

.delete:hover {
  background-color: #F5052D;
  color: #fff;
}

.delete:active {
  background-color: #CC0022;
  color: #fff;
}

.cancel {
  border: 2px solid #B3B3B3;
  border-radius: 8px;
  color: #000000;
}

.cancel:active {
  border-color: #808080;
  background-color: #808080;
  color: #fff;
}

.cancel:hover {
  border-color: #B3B3B3;
  background-color: #B3B3B3;
  color: #fff;
}