.OnlineStatusListWrap.hide {
  display: none;
}

.tableContainer {
  overflow-x: scroll;
  height: calc(100vh - 180px);
  display: flex;
  flex-direction: column;
}

.tableWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  height: 48px;
  min-width: 1700px;
}

.tableContent {
  background-color: #fff;
  border-radius: 8px;
  margin-top: 20px;
}

.tableItem {
  background: #fff;
  border-radius: 8px;
  height: 100%;
  display: flex;
  align-items: center;
  font: normal normal 600 16px/21px InterMedium;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
  box-sizing: border-box;
  padding-left: 0.8%;
  flex: 1;
}

.name {
  word-break: break-all;
}

.tableHeader .tableItem.onlineStatus {
  display: flex;
}

.tableHeader .tableItem.onlineStatus > img {
  margin: 0rem 0.5rem;
}

.tableContent .tableItem.onlineStatus > div {
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
}

.tableContent .tableItem.onlineStatus .statusColor {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.tableContent .tableItem.onlineStatus .online {
  background-color: #009135;
}

.tableContent .tableItem.onlineStatus .offline {
  background-color: #e60026;
}

.tableItem.role {
  padding-left: 0;
}

.tableItem.idle {
  padding-left: 0;
}

.tableItem .signOut {
  color: #e60026;
  cursor: pointer;
}

.paginationWrap {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.messageBox {
  background-color: #fff;
  width: 100%;
  height: 112px;
  border-radius: 8px;
  font: normal normal normal 24px/29px InterRegular;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
  color: #888888;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.refreshRow {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  align-self: flex-end;
}

.updateText {
  font: normal normal 600 14px/17px InterRegular;
}

.updateNum {
  color: #e60026;
  font: normal normal 600 14px/17px InterRegular;
}

.refresh {
  background-color: transparent;
  color: #1452CC;
  cursor: pointer;
  width: 83px;
  height: 24px;
  border: none;
  font: normal normal 600 14px/17px InterRegular;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  gap: 10px;
}

.refresh:active {
  color: #E3E3E3;
}

.refresh.disabled {
  color: #E3E3E3;
  /* cursor: not-allowed; */
  pointer-events: none;
}
