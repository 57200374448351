.modalWrap {
  width: 1280px;
  height: 640px;
  background-color: #fff;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 60px;
}

.titleWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.titleWrap .title {
  font: normal normal 600 24px/29px InterMedium;
}

.titleWrap .cancel {
  cursor: pointer;
  transform: scale(1.6);
}

.loadingWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #0000008A;
  z-index: 10;
}

.utilityRowWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 38px;
}

.left, .right {
  display: flex;
  align-items: center;
  gap: 20px; 
}

.btn {
  width: 146px;
  height: 48px;
  background-color: #FFF;
  border: 1px solid #1452CC;
  border-radius: 8px;
  font: normal normal normal 14px/17px InterRegular;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;
}

.addUser {
  width: 162px;
}

.download {
  text-decoration: none;
  color: #1452CC;
}

.upload, .addUser {
  background-color: #1452CC;
  color: #fff;
}

.upload:hover, .download:hover, .addUser:hover {
  background-color: #175CE6;
  color: #fff;
}

.upload:active, .download:active, .addUser:active {
  background-color: #1142A6;
  color: #fff;
}

.download.disabled , .upload.disabled, .addUser.disabled {
  background-color: #DCDEE0;
  border-color: #DCDEE0;
  pointer-events: none;
}

.container {
  width: 100%;
  height: 345px;
  background-color: #F5F8FF;
  border: 1px solid #B3B3B3;
  border-radius: 8px;
  margin-top: 12px;
  box-sizing: border-box;
  padding: 23px 23px 11px 23px;
  overflow-y: scroll;
}

.bottomWrap {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 24px;
}

.tableWrap {
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: #fff;
  margin-bottom: 12px;
  height: 31px;
  padding: 10px;
}

.tableContent.disabled {
  background-color: #EBEDF0;
}

.tableItem {
  display: flex;
  align-items: center;
  font: normal normal 600 14px/17px InterRegular;
  box-sizing: border-box;
  padding-left: 10px;
}

.tableContent .tableItem {
  font: normal normal normal 14px/17px InterRegular;
}

.checkBox.disabled {
  visibility: hidden;
  pointer-events: none;
}

.checkBox {
  width: calc(100% / 13);
}

.firstName, .lastName {
  width: calc(100% / 13 * 2);
}

.email, .status {
  width: calc(100% / 13 * 4);
}

.tableContent .status {
  color: #E60026;
}

.slotWrap {
  display: flex;
  align-items: center;
  gap: 36px;
}

.slotItem {
  display: flex;
  align-items: center;
  font: normal normal 600 14px/17px InterRegular;
}

.slotItem.fade {
  opacity: 0.4;
}

.slotItem .slot {
  color: #0056FF;
  font: normal normal 600 14px/17px InterRegular;
}

.slotItem .slot.warning {
  color:#FF0000;
}

.status.saved {
  color: #1452CC;
}

.loadingWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #0000008A;
  z-index: 10;
}

.inputFile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.inputFile + label {
  font-size: 1.25em;
  font-weight: 700;
  background-color: #1452CC;
  border: 1px solid #1452CC;
  color: #fff;
  width: 146px;
  height: 48px;
  border-radius: 8px;
  font: normal normal normal 14px/17px InterRegular;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;
  text-align: center;
}

.inputFile + label:hover {
  background-color: #175CE6;
  border: 1px solid #175CE6;
}

.inputFile + label:active {
  background-color: #1142A6;
  border: 1px solid #1142A6;
}