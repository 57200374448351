.noResults {
  background-color: #fff;
  height: 270px;
  padding-top: 2rem;
}

.noResults > p {
  font: normal normal 400 18px/32px InterRegular;
  color: #808080;
  text-align: center;
}

.questionArea {
  max-height: calc(85vh - 200px);
  overflow: scroll;
}

.questionArea .question {
  margin-bottom: 2rem;
  background-color: #fff;
  padding: 1rem 3rem 1rem 3rem;
  border-radius: 8px;
}

.questionArea .title {
  display: flex;
  align-items: center;
}

.questionArea .title h1 {
  font: normal normal 600 18px/21px InterRegular;
  margin-right: 1rem;
}

.questionArea .questionTypeIcon {
  border: 2px solid #1452cc;
  border-radius: 4px;
  color: #1452cc;
  padding: 0.2rem 0.5rem;
  margin-right: 1rem;
}

.questionArea h2 {
  font: normal normal 400 18px/21px InterRegular;
  margin-bottom: 1rem;
}

.questionArea .option {
  display: grid;
  grid-template-columns: 0.4fr 1.5fr 0.4fr 0.2fr 0.5fr;
  background-color: #ebedf099;
  padding: 0.7rem 1.5rem;
  border-radius: 10px;
  margin: 1.5rem 0rem 1rem 0rem;
  font: normal normal 600 18px/21px InterRegular;
}

.question > img {
  max-height: 350px;
  margin-bottom: 1rem;
}

.option .studentCount {
  display: flex;
  align-items: center;
}

.option .studentCount img {
  margin-right: 0.5rem;
}

.option .barCol > div {
  height: 100%;
  border-radius: 5px;
}

.option .barCol.correct > div {
  background-color: #009135;
}

.option .barCol.incorrect > div {
  background-color: #e60026;
}

.option .barCol.null > div {
  background-color: #888888;
}
