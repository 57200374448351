.modalWrap {
  width: 732px;
  height: 370px;
  background-color: #fff;
  border: 1px solid #707070;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.titleWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon {
  cursor: pointer;
  transform: scale(1.5);
}

.title {
  font: normal normal bold 36px/60px InterMedium;
}

.save {
  background-color: #175CE6;
  color: #fff;
  cursor: pointer;
  width: 162px;
  height: 48px;
  border-radius: 8px;
  border: none;
  font: normal normal 600 18px/21px InterRegular;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
}

.save:active {
  background-color: #1142A6;
}

.save.disabled {
  background-color: #E3E3E3;
  /* cursor: not-allowed; */
  pointer-events: none;
}

.groupName {
  margin-bottom: 10px;
}