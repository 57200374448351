.wrap {
  box-sizing: border-box;
  padding: 48px;
  background-color: #F5F8FF;
  min-height: calc(100vh - 72px);
}

.title {
  font: normal normal 600 36px/44px InterMedium;
  font-size: clamp(2rem, 1.5em + 0.5vw, 2.3rem);
  margin-bottom: 24px;
}