.card {
  background-color: #fff;
  border-radius: 8px;
  padding: 2rem 1rem;
  text-align: center;
  box-sizing: border-box;
  width: 270px;
}

h1 {
  font: normal normal 600 24px/29px InterRegular;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.avatarImg {
  width: 64px;
  height: 64px;
  border-radius: 50%;
}

.content {
  width: fit-content;
  margin: 0 auto;
}

.content > div {
  display: flex;
  align-items: center;
  margin: 0.5rem 0rem;
  font: normal normal 600 16px/20px InterRegular;
  width: fit-content;
}

.content > div img {
  margin-right: 01rem;
  width: 16px;
}

.content > div > p > span {
  color: #1452cc;
  margin-right: 0.5rem;
}

.attendance,
.attendance > div {
  display: flex;
  align-items: center;
}

.attendance > div .minutes {
  color: #ee411e;
}

.attendance > div {
  margin-right: 0.5rem;
}

.attendance div.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.attendance div.dot.onTime {
  background-color: #309e58;
}

.attendance div.dot.absence {
  background-color: #b3b3b3;
}

.attendance div.dot.late {
  background-color: #eed21e;
}

.attendance div.dot.early {
  background-color: #ee941e;
}

.attendance div.dot.lateAndEarlyLeave {
  background-color: #e60026;
}
