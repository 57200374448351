.menuWrap {
  width: 256px;
  background-color: #FFF;
  box-shadow: 0px 0px 6px #00000033;
  border-radius: 8px;
}

.menuOption {
  display: flex;
  align-items: center;
  padding: 15px;
  font: normal normal 600 18px/21px InterLight;
  height: 18px;
  cursor: pointer;
}

.menuOption:hover {
  background-color: #f1f1f1;
}

.menuWrap .menuOption:first-child {
  /* border-bottom: 1px solid #e3e3e3; */
}
