.modalWrap {
  width: 596px;
  height: 331px;
  background-color: #fff;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 48px 35px 48px 48px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  margin-bottom: 21px;
  font: normal normal 600 24px/29px InterRegular;
}

.message {
  font: normal normal normal 18px/36px InterRegular;
}

.btnWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 41px;
}

.btn {
  width: 144px;
  height: 48px;
  background: #ffffff 0% 0% no-repeat padding-box;
  font: normal normal normal 14px/17px InterRegular;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.confirm {
  border: 2px solid #1452cc;
  background-color: #1452cc;
  color: #fff;
}

.confirm:hover {
  border: 2px solid #175ce6;
  background-color: #175ce6;
}

.confirm:active {
  border: 2px solid #1142a6;
  background-color: #1142a6;
}

.cancel {
  border: 2px solid #b3b3b3;
  color: #000000;
}

.cancel:hover {
  border: 2px solid #b3b3b3;
  background-color: #b3b3b3;
  color: #fff;
}

.cancel:active {
  border: 2px solid #808080;
  background-color: #808080;
  color: #fff;
}
