.changeDateCard {
  display: flex;
}

.changeDateCard .arrow,
.changeDateCard .date {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 8px;
  height: 32px;
}

.changeDateCard .date {
  padding: 0.5rem 2rem;
  font: normal normal 600 14px/17px InterRegular;
  margin: 0rem 1rem;
}

.changeDateCard .arrow {
  width: 32px;
  padding: 0.5rem;
  cursor: pointer;
}

.changeDateCard .arrow.disabled {
  opacity: 0.5;
  cursor: unset;
}
