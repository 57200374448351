.weekWrap {
  display: flex;
}

.weekWrap .dayWrap:last-child {
  border-right: 1px solid #707070;
}

.weekWrap:last-child .dayWrap {
  border-bottom: 1px solid #707070;
}

.dayWrap {
  box-sizing: border-box;
  padding: 4px 8px;
  /* width: 184px; */
  width: calc(100% / 7);
  height: 172px;
  background-color: #FFFFFF;
  border: 1px solid #707070;
  border-right: none;
  border-bottom: none;
  font: normal normal normal 24px/30px InterMedium;
  /* overflow: scroll; */
}

.dayWrap::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.dateText {
  font: normal normal 600 18px/21px InterRegular;
}

.dayWrap.grayText {
  color:rgba(180, 180, 180, 0.5);
  /* pointer-events: none; */
}

.circle.grayText {
  color:rgba(180, 180, 180, 0.5);
  background-color: #a2aec7;
}

.singleClassWrap {
  height: 140px;
  overflow: scroll;
}

.singleClassWrap::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.singleClass {
  margin-top: 5px;
  cursor: pointer;
  box-sizing: border-box;
  padding: 2px;
  border-radius: 8px;
  overflow: scroll;
}

.singleClass::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.singleClass.active, .singleClass:hover.active {
  background-color: #1452CC;
  color: #fff;
}

.singleClass:hover {
  background-color: #DCDEE0;
}

.singleClass.active .circle {
  background-color: #fff;
}

.firstRow {
  display: flex;
  align-items: center;
  /* white-space: nowrap; */
}

.circle {
  min-width: 12px;
  height: 12px;
  background-color: #1452CC;
  border-radius: 50%;
}

.firstRow .courseName {
  padding-left: 5px;
  font: normal normal 600 18px/21px InterRegular;
}

.secondRow {
  /* white-space: nowrap; */
  padding-left: 15px;
  margin-top: 5px;
  font: normal normal normal 18px/20px InterRegular;
  color: #B3B3B3;
}

.blocksWrap {
  position: relative;
  /* width: calc(184px * 7); */
}

.spinnerWrap {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(180, 180, 180, 0.5);
}