.editScheduleWrap {
  position: absolute;
  top: 270px;
  right: 500px;
  width: 900px;
  height: 490px;
  background-color: #fff;
  box-shadow: 0px 0px 6px #00000066;
  border: 1px solid #707070;
  border-radius: 8px;
  padding: 30px 50px;
  box-sizing: border-box;
  z-index: 150;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.titleWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.titleWrap .title {
  font: normal normal 600 24px/29px InterRegular;
}

.titleWrap .cancel {
  cursor: pointer;
  transform: scale(1.5);
}

.firstRow {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin-top: 31px;
}

.firstRow .courseName {
  flex: 2;
}

.firstRow .teacherName {
  flex: 1;
}

.firstRow .classroom {
  flex: 1;
}

.secondRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  margin-top: 24px;
}

.secondRow .left {
  display: flex;
  align-items: center;
  gap: 24px;
  flex: 2;
}

.secondRow .left .timePickerWrap {
  flex: 1;
}

.secondRow .timePickerWrap {
  flex: 1;
}

.thirdRow {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  gap: 24px;
}

.thirdRow .frequency {
  min-width: 284px;
}

.weekdayWrap {
  display: flex;
  gap: 23px;
}

.weekdayWrap.disabled .weekDayBtn {
  background-color: #fff;
  border: 1px solid #e3e3e3;
  color: #e3e3e3;
  pointer-events: none;
}

.block {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font: normal normal normal 14px/17px InterRegular;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
}

.weekDayBtn {
  border: 1px solid #b3b3b3;
}

.weekDayBtn.active {
  background-color: #1452cc;
  color: #fff;
}

.forthRow {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}

.forthRow.disabled {
  display: none;
}

.timStrWrap {
  width: 150px;
  font: normal normal normal 14px/17px InterRegular;
  display: flex;
  align-items: center;
}

.timStrWrap.disabled {
  visibility: hidden;
  pointer-events: none;
}

.fifthRow {
  display: flex;
  margin-top: 24px;
  gap: 24px;
  align-items: center;
}

.fifthRow .title {
  font: normal normal normal 24px/29px InterRegular;
}

.fifthRow .classroom {
  width: 350px;
}

.confirmModalWrap {
  width: 600px;
  height: 290px;
  background-color: #fff;
  border: 1px solid #707070;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 48px;
}

.confirmModalWrap .title {
  font: normal normal 600 24px/29px InterRegular;
}

.confirmModalWrap .content {
  font: normal normal normal 18px/36px InterRegular;
  margin-top: 24px;
}

.confirmModalWrap .btnWrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 28px;
  margin-top: 48px;
}

.confirmModalWrap .btnWrap .btn {
  width: 144px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  font: normal normal normal 18px/36px InterRegular;
}

.confirmModalWrap .btnWrap .cancel {
  background-color: #fff;
  border: 1px solid #b3b3b3;
  color: #000;
}

.confirmModalWrap .btnWrap .cancel:hover {
  background-color: #b3b3b3;
  color: #fff;
}

.confirmModalWrap .btnWrap .cancel:active {
  background-color: #808080;
  color: #fff;
}

.confirmModalWrap .btnWrap .action.Delete {
  background-color: #fff;
  border: 1px solid #e60026;
  color: #e60026;
}

.confirmModalWrap .btnWrap .action.Delete:hover {
  background-color: #e60026;
  color: #fff;
}

.confirmModalWrap .btnWrap .action.Delete:active {
  background-color: #cc0022;
  color: #fff;
}

.confirmModalWrap .btnWrap .action.Save,
.confirmModalWrap .btnWrap .action.Discard {
  background-color: #fff;
  border: 1px solid #1452cc;
  color: #1452cc;
}

.confirmModalWrap .btnWrap .action.Save:hover,
.confirmModalWrap .btnWrap .action.Discard:hover {
  background-color: #175ce6;
  color: #fff;
}

.confirmModalWrap .btnWrap .action.Save:active,
.confirmModalWrap .btnWrap .action.Discard:active {
  background-color: #1142a6;
  color: #fff;
}

.btnWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 45px; */
}

.btn {
  width: 150px;
  height: 48px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  font: normal normal normal 14px/17px InterRegular;
}

.delete {
  border: 1px solid #ff0000;
  color: #ff0000;
}

.delete.disabled {
  visibility: hidden;
  pointer-events: none;
}

.delete:hover {
  background-color: #e60026;
  color: #fff;
}

.delete:active {
  background-color: #cc0022;
  color: #fff;
}

.save {
  border: 1px solid #1452cc;
  color: #1452cc;
}

.save.disabled,
.save.disabled:hover,
.save.disabled:active {
  background-color: #dcdee0;
  border-color: #dcdee0;
  color: #fff;
  pointer-events: none;
}

.save:hover {
  background-color: #175ce6;
  color: #fff;
}

.save:active {
  background-color: #1142a6;
  color: #fff;
}
