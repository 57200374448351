.breadCrumbs {
  display: flex;
  gap: 24px;
  align-items: center;
  margin-bottom: 1.5rem;
}

.pointer {
  cursor: pointer;
}

.breadCrumbs .tab {
  font: normal normal 600 24px/29px InterRegular;
  font-size: clamp(1.4rem, 0.4em + 0.8vw, 1.6rem);
}

.titleWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: calc(2rem - 7px);
}

.titleWrap > div {
  display: flex;
  align-items: center;
}

.titleWrap h1 {
  font: normal normal 600 36px/44px InterMedium;
  font-size: clamp(2rem, 1.5em + 0.5vw, 2.3rem);
  height: 48px;
}

.filterArea {
  display: flex;
  align-items: center;
  font: normal normal 600 14px/17px InterRegular;
  margin-right: 2rem;
}

.filterArea > p {
  min-width: fit-content;
  margin-right: 1rem;
}

.filterArea > div {
  margin-right: 1rem;
}

.cardList {
  display: flex;
  flex-wrap: wrap;
}

.cardList > div {
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 0 0 1px #f5f8ff;
  margin: 0rem 2rem 2rem 1rem;
}

.cardList > div:hover {
  box-shadow: 0 0 0 1px #1452cc;
}
