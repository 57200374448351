.permissionWrap {
  width: 100%;
  /* min-width: 1450px; */
  min-height: 100vh;
  background-color: #f5f8ff;
  box-sizing: border-box;
  padding: 45px 67px 45px 45px;
}

.utilityWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.block {
  background-color: #fff;
  border-radius: 8px;
  min-height: 145px;
  width: 100%;
  box-sizing: border-box;
  padding: 48px 45px;
  display: flex;
  align-items: flex-start;
  gap: 24px;
}

.title {
  font: normal normal 600 18px/27px InterRegular;
  color: #000000;
  display: flex;
  align-items: center;
  gap: 8px;
}

.description {
  font: normal normal normal 14px/26px InterMedium;
  color: #808080;
}
