.searchInputWrap {
  width: 323px;
  margin-bottom: 3rem;
}

.titleWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  gap: 20px;
  width: 100%;
}

.titleWrap div:first-child {
  width: calc(100% - 190px);
}

.titleWrap div:last-child {
  width: 190px;
}

.tableContainer {
  box-sizing: border-box;
  height: calc(100vh - 23px);
  overflow-x: scroll;
}

.tableItem {
  border-radius: 8px;
  height: 100%;
  background-color: #fff;
  font: normal normal 600 18px/21px InterRegular;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0.5rem 1.2rem;
}

.accordion {
  margin: 1.3rem 0rem;
}

.accordion .summary {
  width: 100%;
  display: flex;
  align-items: center;
  font: normal normal 600 18px/21px InterRegular;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
}

.summary > .title {
  display: table;
  table-layout: fixed;
  width: calc(100% - 170px);
}

.summary > .title p {
  padding-right: 1.5rem;
  display: table-cell;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.summary .export {
  display: flex;
  color: #1452cc;
}

.export img {
  margin-right: 0.5rem;
}

.export .text {
  margin-left: 0.5rem;
}

.details {
  display: flex;
  font: normal normal 600 18px/21px InterRegular;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
  padding: 1rem 0rem 3rem 0rem;
}

.left {
  width: calc(100% - 160px - 40px);
  margin-left: 40px;
  align-items: center;
  flex-wrap: wrap;
}

.left > div {
  display: flex;
}

.left > div > p,
.left > div > div {
  margin-right: 3rem;
}

.right {
  cursor: pointer;
  align-items: center;
}

.left,
.right {
  display: flex;
  padding: 1rem 0rem 4rem 0rem;
}

.details:not(:last-child) .left,
.details:not(:last-child) .right {
  border-bottom: 1px solid #e3e3e3;
}

.details:last-child .left,
.details:last-child .right {
  padding: 1rem 0rem 1rem 0rem;
}

.right img {
  width: 1rem;
  margin-right: 0.5rem;
}

.right p {
  color: #1452cc;
}

.left > div > p {
  margin-bottom: 0.5rem;
}

.cardArea {
  display: flex;
}

.cardArea > div {
  margin-right: 0.5rem;
}

.largeSize {
  font-size: 1.5rem;
  font: normal normal 600 24px/29px InterRegular;
  margin-right: 0.5rem;
}

.smallSize {
  font-size: 1rem;
  font: normal normal 600 11px/14px InterRegular;
  margin-right: 0.3rem;
}

.paginationWrap {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.noResult {
  background-color: #fff;
  border-radius: 15px;
  padding: 1rem 2rem 3rem 2rem;
  margin-top: 1.5rem;
}

.noResult h2 {
  font: normal normal 600 24px/29px InterMedium;
}

.noResult p {
  font: normal normal 600 18px/21px InterRegular;
}

@media screen and (max-width: 1500px) {
  .left > .data {
    margin-top: 1.5rem;
  }
}
