.scheduleInfoWrap {
  position: absolute;
  top: 350px;
  right: 600px;
  background-color: #FFF;
  box-shadow: 0px 0px 6px #00000066;
  border: 1px solid #707070;
  border-radius: 8px;
  padding: 50px 50px 20px 50px;
  width: 600px;
  box-sizing: border-box;
}

.courseName {
  font: normal normal 600 24px/29px InterRegular;
}

.row {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 24px;
}

.durationRow {
  display: flex;
  align-items: center;
  gap: 24px;
  width: 100%;
  margin-bottom: 24px;
  color: #808080;
}

.btnWrap {
  margin-top: 50px;
}

.ymd, .hm {
  font: normal normal normal 16px/20px InterRegular;
}

.title {
  font: normal normal 600 18px/20px InterRegular;
}

.content {
  font: normal normal normal 18px/20px InterRegular;
}

.btn {
  width: 150px;
  height: 48px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  font: normal normal normal 18px/24px InterRegular;
  gap: 12px;
}

.btnWrap.disabled {
  display: none;
}

.edit {
  border: 1px solid #1452CC;
  color: #1452CC;
  background-color: #fff;
}

.edit.active {
  background-color: #1142A6;
  color: #fff;
}

.edit:active {
  background-color: #1142A6;
  color: #fff;
}

.edit:hover {
  background-color: #175CE6;
  color: #fff;
}

.icon {
  transform: scale(1.2);
  margin-top: 5px;
}

.icon.hidden {
  display: none;
}

.close {
  transform: scale(1.5);
  cursor: pointer;
}