.wrap {
  min-height: 100%;
}

.wrap.hide {
  display: none;
}

.utilityRow {
  display: flex;
  justify-content: space-between;
}

.utilityRow .left {
  display: flex;
  gap: 24px;
}

.searchInputWrap {
  width: 323px;
}

.teacherSelect {
  width: 230px;
}

.teacherSelect.hidden {
  visibility: hidden;
}

.classBlocks {
  display: flex;
  gap: 25px;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 24px;
}

.classBlock {
  flex: 1;
  min-width: 774px;
  max-width: 774px;
  height: 254px;
  background-color: #fff;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 8px;
  padding: 24px;
  box-sizing: border-box;
  background-color: #fff;
  cursor: pointer;
}

.classBlock .top {
  display: flex;
  justify-content: space-between;
  font: normal normal normal 16px/20px InterRegular;
  color: #888888;
}

.classBlock .bottom {
  display: flex;
  margin-top: 14px;
}

.bottom .left {
  flex: 3;
}

.bottom .right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-Items: flex-end;
  gap: 10px;
}

.courseName {
  height: 116px;
  overflow-y: scroll;
  margin-bottom: 24px;
  font: normal normal 600 24px/29px InterRegular;
}

.teacherName {
  font: normal normal 600 18px/21px InterRegular;
}

.classroom {
  width: 100%;
}

.studentList {
  display: flex;
  gap: 4px;
  color: #888888;
}

.courseName::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.classImage {
  width: 240px;
  height: 114px;
}

.breadCrumbs {
  display: flex;
  gap: 24px;
  align-items: center;
}

.icon {
  cursor: pointer;
}

.breadCrumbs .tab {
  font: normal normal 600 24px/29px InterRegular;
  font-size: clamp(1.4rem, 0.4em + 0.8vw, 1.6rem);
}

.firstRow {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  height: 458px;
}

.secondRow {
  display: flex;
  height: 400px;
  margin-top: 24px;
}

/* .thirdRow {
  display: flex;
  min-height: 400px;
  margin-top: 24px;
} */

.detailPageWrap {
  min-height: 100%;
}

.paginationWrap {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.blockWrap {
  margin-top: 24px;
}

.title {
  font: normal normal 600 24px/29px InterRegular;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
}

.messageBox {
  background-color: #fff;
  width: 100%;
  height: 112px;
  border-radius: 8px;
  font: normal normal normal 24px/29px InterRegular;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
  color: #888888;
  display: flex;
  justify-content: center;
  align-items: center;
}