.modalWrap {
  width: 774px;
  height: 272px;
  background-color: #fff;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 48px;
}

.titleWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.titleWrap .title {
  font: normal normal 600 24px/29px InterRegular;
}

.content {
  margin-top: 10px;
  font: normal normal normal 18px/36px InterRegular;
}

.titleWrap .cancel {
  cursor: pointer;
  transform: scale(1.5);
}

.btnWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin-top: 25px;
}

.btn {
  width: 210px;
  height: 48px;
  border: 2px solid #1452CC;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blue {
  background-color: #1452CC;
  color: #fff;
}

.blue:hover {
  border: 2px solid #175CE6;
  background-color: #175CE6;
}

.blue:active {
  border: 2px solid #1142A6;
  background-color: #1142A6;
}

.blueWhite {
  background-color: transparent;
  color: #1452CC;
}

.blueWhite:hover {
  border: 2px solid #175CE6;
  background-color: #175CE6;
  color: #fff;
}

.blueWhite:active {
  border: 2px solid #1142A6;
  background-color: #1142A6;
}

.grayWhite {
  border: 2px solid #B3B3B3;
  background-color: transparent;
}

.grayWhite:hover {
  background-color: #B3B3B3;
  color: #fff;
}

.grayWhite:active {
  border: 2px solid #808080;
  background-color: #808080;
}