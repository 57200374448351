.modalWrap {
  width: 1000px;
  height: 400px;
  background-color: #fff;
  border: 1px solid #707070;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.titleWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon {
  cursor: pointer;
  transform: scale(1.5);
}

.title {
  font: normal normal 600 24px/60px InterRegular;
}

.textarea {
  align-self: center;
  position: relative;
}

textarea {
  resize: none;
  font: normal normal normal 21px/25px InterRegular;
  padding: 17px 20px;
  box-sizing: border-box;
  width: 100%;
  box-shadow: inset 0px 0px 6px #00000029;
  border-radius: 8px;
  outline: none;
  border: none;
}

textarea::placeholder {
  color: #E3E3E3;
}

.update {
  background-color: #175CE6;
  color: #fff;
  cursor: pointer;
  width: 240px;
  height: 60px;
  border-radius: 8px;
  border: none;
  font: normal normal normal 24px/29px InterRegular;
  align-self: center;
}

.update:active {
  background-color: #1142A6;
}

.update.disabled {
  background-color: #E3E3E3;
  cursor: not-allowed;
}

.lengthHint {
  position: absolute;
  right: 15px;
  bottom: 15px;
  font: normal normal normal 21px/25px InterRegular;
  color: #E3E3E3;
}