.modalWrap {
  width: 770px;
  height: 360px;
  background-color: #fff;
  border: 1px solid #707070;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.titleWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon {
  cursor: pointer;
  transform: scale(1.5);
}

.title {
  font: normal normal 600 24px/29px InterRegular;
}

.row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.row .item {
  flex: 1;
}

.row .left {
  flex: 2;
  display: flex;
  gap: 24px;
}

.row .right {
  flex: 1;
}

.btnWrap {
  display: flex;
  justify-content: flex-end;
}

.update {
  background-color: #175CE6;
  color: #fff;
  cursor: pointer;
  width: 144px;
  height: 48px;
  border-radius: 8px;
  border: none;
  font: normal normal normal 14px/17px InterRegular;
}

.update:active {
  background-color: #1142A6;
}

.update.disabled {
  background-color: #E3E3E3;
  cursor: not-allowed;
}

.right.hide {
  display:  none;
}