.modalWrap {
  width: 595px;
  height: 270px;
  background-color: #fff;
  border-radius: 16px;
  box-sizing: border-box;
  padding: 40px;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.link {
  color: #1452CC;
}

.titleRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  /* margin-bottom: 21px; */
  font: normal normal 600 24px/29px InterBold;
}

.message {
  font: normal normal normal 18px/24px InterRegular;
}

.btnWrap {
  display: flex;
  flex-direction: column;
}

.confirm {
  background-color: #175CE6;
  color: #fff;
  cursor: pointer;
  width: 162px;
  height: 48px;
  border-radius: 8px;
  border: none;
  font: normal normal 600 18px/21px InterRegular;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
}

.confirm:active {
  background-color: #1142A6;
}

.confirm.disabled {
  background-color: #E3E3E3;
  /* cursor: not-allowed; */
  pointer-events: none;
}