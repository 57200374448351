.modalWrap {
  width: 642px;
  min-height: 222px;
  background-color: #fff;
  border: 1px solid #707070;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 45px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
}

.titleWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font: normal normal 600 24px/24px InterRegular;
}

.content {
  font: normal normal 16px/26px InterMedium;
}

.btn {
  background-color: #1452CC;
  color: #fff;
  cursor: pointer;
  width: 144px;
  height: 48px;
  border-radius: 8px;
  border: none;
  font: normal normal normal 14px/17px InterRegular;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
}

.btn:hover {
  background-color: #175CE6;
}

.btn:active {
  background-color: #1142A6;
}

