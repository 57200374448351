.modalWrap {
  width: 595px;
  height: 295px;
  background-color: #fff;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 41px 47px 38px 47px;
}

.title {
  margin-bottom: 21px;
  font: normal normal 600 36px/44px InterBold;
}

.message {
  margin-bottom: 87px;
  font: normal normal normal 18px/21px InterLight;
}

.btnWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.btn {
  width: 144px;
  height: 48px;
  background-color: #FFFFFF;
  font: normal normal normal 14px/36px InterRegular;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-color: transparent;
}

.delete {
  border: 2px solid #F10000;
  color: #F10000;
  margin-right: 24px;
}

.cancel {
  border: 2px solid #707070;
  color: #000000;
}

.delete:hover {
  border: 2px solid #F5052D;
  background-color: #F5052D;
  color: #fff;
}

.delete:active {
  border: 2px solid #CC0022;
  background-color: #CC0022;
  color: #fff;
}

.cancel:hover {
  border: 2px solid #B3B3B3;
  background-color: #B3B3B3;
  color: #fff;
}

.cancel:active {
  border: 2px solid #808080;
  background-color: #808080;
  color: #fff;
}