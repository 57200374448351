.clickable {
  cursor: pointer;
}

.breadCrumbs {
  display: flex;
  gap: 24px;
  align-items: center;
  margin-bottom: 1.5rem;
}

.pointer {
  cursor: pointer;
}

.breadCrumbs .tab {
  font: normal normal 600 24px/29px InterRegular;
  font-size: clamp(1.4rem, 0.4em + 0.8vw, 1.6rem);
}

.title {
  font: normal normal 600 24px/29px InterRegular;
  font-size: clamp(1.4rem, 0.4em + 1vw, 1.6rem);
}

.contentWrap {
  width: 38rem;
  background-color: #fff;
  border-radius: 8px;
  padding: 36px;
}

.contentWrap p {
  font: normal normal 600 18px/21px InterRegular;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
}

.dropdownArea {
  margin: 2rem 0rem;
}

.dropdownArea p {
  margin-bottom: 1rem;
}

.titleWrap {
  display: flex;
  align-items: center;
  margin: 1rem 0rem;
}

.titleWrap img {
  margin-left: 1.5rem;
  cursor: pointer;
}

.titleWrap > span {
  color: red;
}

.questionTypeIcon {
  border: 2px solid #1452cc;
  border-radius: 4px;
  color: #1452cc;
  padding: 0.3rem 1rem;
  margin-right: 1rem;
}

.optionsArea > p {
  font: normal normal 600 18px/21px InterRegular;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
}

.textarea {
  font: unset;
  box-shadow: unset;
  height: 160px;
}

.singleSelection {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.singleSelection img {
  margin-right: 1rem;
}

.singleSelection img:hover {
  cursor: pointer;
}

.addOptionBtn {
  width: 162px;
  height: 48px;
  border-radius: 8px;
  cursor: pointer;
  background-color: #fff;
  color: #1452cc;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #1452cc;
  font: normal normal 600 18px/21px InterRegular;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
  margin: 1rem 0rem 1rem 4rem;
}

.addOptionBtn:hover {
  border: 2px solid #175CE6;
  background-color: #175CE6;
  color: #fff;
}

.addOptionBtn:active {
  border: 2px solid #1142A6;
  background-color: #1142A6;
  color: #fff;
}

.container {
  background-color: rgba(235, 237, 240, 0.4);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem;
}

.saveBtn {
  width: 256px;
  height: 48px;
  border-radius: 8px;
  cursor: pointer;
  background-color: #1452cc;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal normal 14px/17px InterRegular;
  border: none;
  margin: 30px auto 0rem auto;
}

.saveBtn.disabled, .saveBtn.disabled:hover, .saveBtn.disabled:active {
  background-color: #e3e3e3;
  cursor: not-allowed;
}

.saveBtn:hover {
  background-color: #175CE6;
}

.saveBtn:active {
  background-color: #1142A6;
}

.saveBtn.disabled > span {
  margin-left: 0.5rem;
}

.multipleSelection {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.multipleSelection img {
  margin-right: 1rem;
  cursor: pointer;
}

.photoCenter {
  position: relative;
  width: fit-content;
  margin: 0 auto;
}

.photoCenter img {
  height: 250px;
}

.imageArea .delete {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 20px;
  bottom: 20px;
  cursor: pointer;
}

.imageIcon {
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin-left: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../../../assets/icons/image.svg');
}

.imageIcon:hover {
  background-image: url('../../../assets/icons/image_hover.svg');
}

/* .imageIcon.disabled {
  background-image: url('../../../assets/icons/image_disabled.svg');
  pointer-events: none;
} */

.inputFile[type='file'] {
  display: none;
}

.trueFalseOptions {
  display: flex;
  align-items: center;
}

.trueFalseOptions img,
.trueFalseOptions label {
  margin-right: 1rem;
  cursor: pointer;
}

.questionContent {
  min-height: 10rem;
  padding: 0.5rem;
  border: 1px solid #b3b3b3;
  border-radius: 8px;
  margin-bottom: 3rem;
  text-align: center;
}

.questionContent img {
  max-width: 100%;
  margin: 0 auto;
}

.questionContent [contenteditable] {
  outline: 0px solid transparent;
}

.editable {
  border: none;
}

.menuItem p {
  display: inline;
  align-items: center;
}
