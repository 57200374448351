.privacyModalWrap {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 976px;
  height: 405px;
  background-color: #fff;
  box-shadow: 0px 0px 10px #00000033;
  border-radius: 16px;
  display: flex;
  align-items: center;
}

.privacyModalWrap .left {
  flex: 3;
  height: 100%;
  border-radius: 16px 0 0 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.privacyModalWrap .right {
  flex: 2;
  height: 100%;
  border-radius: 0 16px 16px 0;
  box-shadow: -5px 0px 20px #00000033;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 39px;
}

.title {
  font: normal normal 600 18px/21px InterRegular;
}

.content {
  font: normal normal normal 14px/36px InterRegular;
}

.upgrade {
  background-color: #175CE6;
  color: #fff;
  cursor: pointer;
  width: 144px;
  height: 48px;
  border-radius: 8px;
  border: none;
  font: normal normal normal 14px/17px InterRegular;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upgrade:active {
  background-color: #1142A6;
}

.upgrade.disabled {
  background-color: #E3E3E3;
  pointer-events: none;
}
