.modalWrap {
  width: 600px;
  height: 300px;
  background-color: #fff;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 41px 47px 38px 47px;
}

.title {
  margin-bottom: 21px;
  font: normal normal 600 36px/44px InterBold;
}

.message {
  margin-bottom: 87px;
  font: normal normal normal 18px/21px InterLight;
}

.btnWrap {
  display: flex;
  flex-direction: column;
}

.confirm {
  background-color: #175CE6;
  color: #fff;
  cursor: pointer;
  width: 162px;
  height: 48px;
  border-radius: 8px;
  border: none;
  font: normal normal 600 18px/21px InterRegular;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
}

.confirm:active {
  background-color: #1142A6;
}

.confirm.disabled {
  background-color: #E3E3E3;
  /* cursor: not-allowed; */
  pointer-events: none;
}