.modalWrap {
  width: 1280px;
  height: 650px;
  background-color: #fff;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 60px;
}

.titleWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.titleWrap .title {
  font: normal normal 600 24px/29px InterMedium;
}

.titleWrap .cancel {
  cursor: pointer;
  transform: scale(1.6);
}

.utilityRowWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-top: 24px;
  height: 60px;
}

.utilityRowWrap .role {
  flex: 1;
  height: 100%;
}

.utilityRowWrap .name {
  flex: 1;
  height: 100%;
}

.utilityRowWrap .email {
  flex: 2;
  height: 100%;
}

.utilityRowWrap .addBtn {
  width: calc(100% / 6 * 1);
  min-width: 48px;
  max-width: 48px;
  height: 48px;
  margin-top: -10px;
}

.addBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #1452CC;
  color: #fff;
  border-radius: 8px;
}

.addBtn.disabled {
  background-color: #E3E3E3;
  cursor: not-allowed;
}

.tableBoxWrap {
  width: 100%;
  height: 360px;
  background-color: #F5F8FF;
  border: 1px solid #707070;
  border-radius: 16px;
  box-sizing: border-box;
  padding: 23px 23px 11px 23px;
  margin-top: 20px;
  overflow-y: scroll;
}

.tableWrap {
  display: flex;
  gap: 24px;
  height: 48px;
}

.tableWrap .left {
  display: flex;
  width: 100%;
  height: 100%;
  gap: 24px;
}

.tableWrap.tableHeader {
  background-color: #fff;
  border-radius: 8px;
}

.tableWrap.tableContent {
  margin-top: 20px;
  border-radius: 8px;
}

.tableWrap.tableContent .left, .tableWrap.tableContent .delete {
  background-color: #fff;
  border-radius: 8px;
}

.tableWrap.tableContent .delete {
  cursor: pointer;
}

.tableWrap.tableContent.disabled {
  background-color: #E3E3E3;
}

.tableWrap.tableContent.disabled .left, .tableWrap.tableContent.disabled .delete{
  background-color: transparent;
}

.tableWrap.tableContent.disabled .delete {
  visibility: hidden;
  pointer-events: none;
}

.tableWrap .left .tableItem {
  font: normal normal 600 14px/17px InterRegular;
}

.tableItem {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-left: 35px;
}

.tableWrap .left .role {
  width: calc(100% / 9);
  height: 100%;
}

.tableWrap .left .name {
  width: calc(100% / 9 * 3);
  height: 100%;
}

.tableWrap .left .email {
  width: calc(100% / 9 * 5);
  height: 100%;
}

.delete {
  min-width: 48px;
  height: 100%;
  justify-content: center;
  padding: 0;
}

.bottomWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
}

.addAccountBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 162px;
  height: 48px;
  background-color: #1452CC;
  color: #fff;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 8px;
  font: normal normal normal 14px/17px InterRegular;
  cursor: pointer;
}

.addAccountBtn.disabled {
  background-color: #E3E3E3;
  cursor: not-allowed;
}

.selected {
  font: normal normal 600 14px/17px InterRegular;
}

.left {
  display: flex;
  align-items: center;
  gap: 36px;
}

.slotWrap {
  display: flex;
  align-items: center;
  gap: 36px;
}

.slotItem {
  display: flex;
  align-items: center;
  font: normal normal 600 14px/17px InterRegular;
}

.slotItem .slot {
  color: #0056FF;
  font: normal normal 600 14px/17px InterRegular;
}

.slotItem .slot.warning {
  color:#FF0000;
}

.loadingWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #0000008A;
  z-index: 10;
}


