.breadCrumbs {
  display: flex;
  gap: 24px;
  align-items: center;
  margin-bottom: 1.5rem;
}

.pointer {
  cursor: pointer;
}

.breadCrumbs .tab {
  font: normal normal 600 24px/29px InterRegular;
  font-size: clamp(1.4rem, 0.4em + 0.8vw, 1.6rem);
}

.titleWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: calc(2rem - 7px);
}

.titleWrap h1 {
  font: normal normal 600 36px/44px InterMedium;
  font-size: clamp(2rem, 1.5em + 0.5vw, 2.3rem);
  height: 48px;
}

.firstRow {
  display: flex;
}

.whiteBackground {
  background-color: #fff;
  margin-bottom: 50px;
  padding: 3%;
  box-sizing: border-box;
  border-radius: 30px;
}

.whiteBackground > div {
  display: flex;
}

.whiteBackground .title {
  font: normal normal 600 24px/29px InterRegular;
  font-size: clamp(1.4rem, 0.4em + 1vw, 1.6rem);
  margin: 0;
}

.whiteBackground .iconWrap {
  margin-right: 1rem;
}

.blueBtn {
  min-height: 48px;
  border-radius: 8px;
  cursor: pointer;
  background-color: #fff;
  color: #1452cc;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #1452cc;
  font: normal normal 600 18px/21px InterRegular;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
  margin-top: auto;
}

.information,
.attendance {
  height: 463px;
}

.information > div,
.attendance > div {
  height: 100%;
  width: 100%;
}

.information .contentWrap,
.attendance .contentWrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.information {
  flex: 1;
  margin-right: 3rem;
}

.information .contentGrid > div {
  display: grid;
  grid-template-columns: 1fr 4fr;
  font: normal normal 600 18px/21px InterRegular;
  margin: 2rem 0rem;
}

.information .contentGrid > div > span:first-child {
  margin-right: 0.5rem;
  min-width: 65px;
}

.information button {
  width: 162px;
  min-width: fit-content;
}

.information button img {
  margin-right: 0.5rem;
}

.information .disabledBtn {
  height: 48px;
  border-radius: 8px;
  background-color: #b3b3b3;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #b3b3b3;
  font: normal normal 400 18px/21px InterRegular;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
  margin-top: auto;
}

.information .disabledBtn .text {
  margin-left: 0.5rem;
}

.attendance {
  flex: 2;
}

.attendance .titleWrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.attendance .toggleBtnWrap {
  display: flex;
}

.percentageBtn,
.peopleBtn {
  width: 80px;
  height: 36px;
  background-color: transparent;
  border: 1px solid #b3b3b3;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
}

.percentageBtn:hover,
.peopleBtn:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.peopleBtn {
  border-left: 1px solid #1452cc;
}

.percentageBtn {
  border-radius: 8px 0px 0px 8px;
  border-right: none;
}

.percentageBtn.selected {
  background-color: #f5f8ff;
  border-top: 1px solid #1452cc;
  border-left: 1px solid #1452cc;
  border-bottom: 1px solid #1452cc;
}

.peopleBtn {
  border-radius: 0px 8px 8px 0px;
}

.peopleBtn.selected {
  background-color: #f5f8ff;
  border-top: 1px solid #1452cc;
  border-right: 1px solid #1452cc;
  border-bottom: 1px solid #1452cc;
}

.attendance .contentWrap > p {
  margin: 0rem 0rem 1rem 0rem;
  font: normal normal 600 50px/60px InterRegular;
}

.attendance .content {
  font: normal normal 600 18px/21px InterRegular;
}

.attendance .content > div {
  display: flex;
}

.attendance .content > div > div {
  display: flex;
  align-items: center;
  margin: 0.7rem 0rem 0.7rem 0rem;
  width: 300px;
}

.attendance .content > div > div > p {
  margin-left: 0.5rem;
}

.attendance .content .onTime,
.attendance .content .absence,
.attendance .content .late,
.attendance .content .earlyLeave,
.attendance .content .lateAndEarlyLeave {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.attendance .content .onTime {
  background-color: #309e58;
}

.attendance .content .absence {
  background-color: #b3b3b3;
}

.attendance .content .late {
  background-color: #eed21e;
}

.attendance .content .earlyLeave {
  background-color: #ee941e;
}

.attendance .content .lateAndEarlyLeave {
  background-color: #ee411e;
}

.attendance .contentWrap > button {
  padding: 0rem 1.5rem;
  width: 220px;
  min-width: fit-content;
}

.attendance .contentWrap > button.disabled {
  height: 48px;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal 600 18px/21px InterRegular;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
  margin-top: auto;
  color: #b3b3b3;
  border: 2px solid #b3b3b3;
}

.raiseHand .contentWrap {
  width: 100%;
}

.raiseHand .content {
  display: flex;
  font: normal normal 600 18px/21px InterRegular;
  width: 100%;
}

.raiseHand .content > div > span:first-child {
  font: normal normal 600 50px/60px InterRegular;
  margin-right: 0.5rem;
}

.raiseHand .content > div {
  margin-right: 2rem;
}

.raiseHand .avatarArea {
  margin-left: 3rem;
  max-width: 70%;
  overflow: scroll;
}

.raiseHand .avatarArea > div {
  width: fit-content;
}

.raiseHand .avatarWrap {
  display: flex;
}

.raiseHand .avatarWrap > div {
  position: relative;
  margin-right: 0.5rem;
  border-radius: 50%;
  width: 36px;
  height: 36px;
}

.raiseHand .avatarWrap > div .arrow {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #f5f8ff;
  top: 45px;
  right: 13px;
  transform: rotate(45deg);
  border-top: 1px solid #1452cc;
  border-left: 1px solid #1452cc;
}

.raiseHand .avatarWrap > div .avatarImg {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
}

.raiseHand .avatarWrap > div.normal:hover .avatarImg {
  opacity: 0.5;
}

.raiseHand .avatarWrap > div.normal:hover .blueEyeImg {
  z-index: 5;
}

.raiseHand .avatarWrap > div.selected {
  background-color: #000;
}

.raiseHand .avatarWrap > div.selected .avatarImg {
  opacity: 0.5;
}

.raiseHand .avatarWrap .blueEyeImg,
.raiseHand .avatarWrap .whiteEyeImg {
  width: 13px;
  position: absolute;
  left: 12px;
  top: 15px;
  cursor: pointer;
}

.raiseHand .avatarWrap .blueEyeImg {
  z-index: -1;
}

.raiseHand .avatarWrap > div > div {
  width: 20px;
  height: 20px;
  top: 0px;
  right: 0px;
}

.raiseHand .timestampWrap {
  background-color: #f5f8ff;
  border: 1px solid #1452cc;
  border-radius: 8px;
  padding: 1rem 1.5rem;
  margin-top: 15px;
}

.raiseHand .timestampWrap > div {
  display: flex;
  flex-wrap: wrap;
}

.raiseHand .timestampWrap > div > p {
  margin-right: 1rem;
}

.raiseHand .timestampWrap > div > p > span {
  margin-right: 0.3rem;
}

.raiseHand .timestampWrap .time div:not(:last-child) {
  padding-right: 0.8rem;
  margin-right: 0.8rem;
  border-right: 1px solid #b3b3b3;
}

.raiseHand .timestampWrap .time > div {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.raiseHand .timestampWrap .time > div > span {
  display: flex;
  align-items: center;
  height: 28px;
}

.raiseHand .timestampWrap .time div div:last-child {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background-color: #e8e8e8;
  border-radius: 8px;
  margin-left: 0.5rem;
}

.raiseHand .noRecordsFound {
  display: flex;
  align-items: end;
  padding-bottom: 0.5rem;
  margin-left: 5rem;
}

.raiseHand .noRecordsFound > div {
  background-color: #f5f8ff;
  color: #1452cc;
  border: 1px solid #1452cc;
  border-radius: 8px;
  padding: 0.5rem 2rem;
  height: 1.5rem;
}

.quiz .contentWrap {
  width: 100%;
}

.quiz .content .metrics {
  display: flex;
}

.quiz .content .metrics > div {
  margin-right: 2rem;
}

.quiz .content .metrics > div > span:first-child {
  font: normal normal 600 50px/60px InterRegular;
  margin-right: 0.5rem;
}

.quiz .content .metrics > div > span:last-child {
  font: normal normal 600 18px/21px InterRegular;
}

.quiz .tableTitleWrap {
  display: flex;
  color: #1452cc;
  font: normal normal 600 18px/21px InterRegular;
  padding: 3rem 0rem 1rem 0rem;
  border-bottom: 1px solid #e3e3e3;
}

.quiz .tableTitleWrap .largeCol {
  width: calc(100% - 450px);
}

.quiz .tableTitleWrap .smallCol {
  min-width: 150px;
  text-align: center;
}

.quiz .summary .largeCol,
.quiz .question .largeCol {
  width: calc(100% - 450px);
}

.quiz .summary .smallCol,
.quiz .question .smallCol {
  min-width: 150px;
  text-align: center;
}

.quiz .accordion {
  margin: 0.7rem 0rem;
}

.quiz .summary {
  display: flex;
  align-items: center;
  width: 100%;
  font: normal normal 600 18px/21px InterRegular;
}

.quiz .summary .largeCol {
  width: calc(100% - 400px);
}

.quiz .summary .quizName {
  display: table;
  table-layout: fixed;
}

.quiz .summary .quizName > p {
  padding-right: 1rem;
  display: table-cell;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.quiz .details {
  padding-left: 36px;
}

.quiz .question {
  display: flex;
  font: normal normal 600 18px/21px InterRegular;
  background-color: #ebedf099;
  border-radius: 8px;
  padding: 1rem 0rem;
  margin: 1rem 0rem 1.5rem 0rem;
}

.quiz .question .questionContent {
  display: table;
  table-layout: fixed;
}

.quiz .question .questionContent p {
  padding-right: 1rem;
  display: table-cell;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.quiz .question .largeCol {
  padding-left: 16px;
  width: calc(100% - 400px);
}

.quiz .question .largeCol > span {
  margin-right: 0.5rem;
}

.quiz .noRecordsFound {
  display: flex;
  align-items: end;
  padding-bottom: 0.5rem;
  margin-top: 1.5rem;
}

.quiz .noRecordsFound > div {
  background-color: #f5f8ff;
  color: #1452cc;
  border: 1px solid #1452cc;
  border-radius: 8px;
  padding: 0.5rem 2rem;
  height: 1.5rem;
  font: normal normal 600 18px/21px InterRegular;
}

.imageContent {
  display: flex;
  align-items: center;
}

.imageContent img {
  height: 25px;
}

@media screen and (max-width: 1500px) {
  .attendance {
    flex: 1.5;
  }
}
