.menuWrap {
  background-color: #fff;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0px 0px 6px #00000033;
}

.option {
  height: 47px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 15px;
  width: 149px;
  gap: 8px;
}

.option.red {
  color: #E60026;
}

.option.blue:hover {
  background-color: #DCDEE0;
  color: #1452CC;
}

.option.red:hover {
  background-color: #F5052D;
  color: #fff;
}

.icon {
  width: 24px;
  height: 24px;
  background-position: center;
  background-repeat: no-repeat;
}

.edit {
  background-image: url('../../../assets/icons/quiz/editPen.svg');
}

.duplicate {
  background-image: url('../../../assets/icons/quiz/duplicate.svg');
}

.delete {
  background-image: url('../../../assets/icons/quiz/delete.svg');
}

.option:hover .edit {
  background-image: url('../../../assets/icons/quiz/editPen_hover.svg');
}

.option:hover .duplicate {
  background-image: url('../../../assets/icons/quiz/duplicate_hover.svg');
}

.option:hover .delete {
  background-image: url('../../../assets/icons/quiz/delete_hover.svg');
}