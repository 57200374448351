.modalWrap {
  /* width: 800px; */
  /* height: 300px; */
  background-color: #fff;
  border: 1px solid #707070;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.icon {
  cursor: pointer;
  transform: scale(1.5);
}

.titleWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font: normal normal 600 36px/44px InterRegular;
}

.content {
  font: normal normal normal 18px/21px InterRegular;
}