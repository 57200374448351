.title {
  font: normal normal 600 24px/29px InterRegular;
  margin-bottom: 24px;
}

.message {
  font: normal normal normal 18px/21px InterRegular;
  margin-bottom: 21px;
}

.spotWrap, .spotItem {
  display: flex;
  align-items: center;
  margin-bottom: 22px;
}

.spotItem .role {
  font: normal normal 600 18px/21px InterMedium;
}

.spotItem .spot {
  font: normal normal normal 18px/21px InterRegular;
  color: #0056FF;
  margin-right: 29px;
  margin-left: 5px;
}

.btnWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.btn {
  width: 144px;
  height: 48px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 6px #00000029;
  font: normal normal normal 14px/17px InterRegular;
  cursor: pointer;
}

.cancel {
  border: 2px solid #707070;
  border-radius: 8px;
  color: #888888;
}

.cancel:active {
  border-color: #808080;
  background-color: #808080;
  color: #fff;
}

.cancel:hover {
  border-color: #B3B3B3;
  background-color: #B3B3B3;
  color: #fff;
}

.confirm {
  border: 2px solid #1452CC;
  color: #1452CC;
  border-radius: 8px;
  margin-left: 24px;
}

.confirm:hover {
  background-color: #1452CC;
  color: #fff;
}

.confirm:active {
  background-color: #1142A6;
  color: #fff;
}