.breadCrumbs {
  display: flex;
  gap: 24px;
  align-items: center;
  margin-bottom: 1.5rem;
}

.pointer {
  cursor: pointer;
}

.breadCrumbs .tab {
  font: normal normal 600 24px/29px InterRegular;
  font-size: clamp(1.4rem, 0.4em + 0.8vw, 1.6rem);
}

.contentWrap {
  display: flex;
  width: 100%;
}

.contentWrap .left {
  margin-right: 3rem;
  width: 270px;
}

.contentWrap .right {
  width: 100%;
}

.titleWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: calc(2rem - 7px);
}

.titleWrap h1 {
  font: normal normal 600 36px/44px InterMedium;
  font-size: clamp(2rem, 1.5em + 0.5vw, 2.3rem);
  height: 48px;
}

.whiteBackground {
  width: 100%;
  background-color: #fff;
  margin-bottom: 50px;
  padding: 3%;
  box-sizing: border-box;
  border-radius: 8px;
  font: normal normal 600 18px/21px InterRegular;
}

.whiteBackground > div {
  display: flex;
}

.whiteBackground .title {
  font: normal normal 600 24px/29px InterRegular;
  font-size: clamp(1.4rem, 0.4em + 1vw, 1.6rem);
  margin: 0;
}

.whiteBackground .iconWrap {
  margin-right: 1rem;
}

.attendance .content > div {
  display: flex;
  align-items: center;
  margin: 2rem 0rem 0rem 0rem;
}

.attendance .content > div > p:first-child {
  margin-right: 1rem;
}

.attendance .content .statusCard {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
}

.attendance div.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.attendance div.dot.onTime {
  background-color: #309e58;
}

.attendance div.dot.absence {
  background-color: #b3b3b3;
}

.attendance div.dot.late {
  background-color: #eed21e;
}

.attendance div.dot.early {
  background-color: #ee941e;
}

.attendance div.dot.lateAndEarlyLeave {
  background-color: #e60026;
}

.attendance > div .date {
  margin-right: 1rem;
}

.attendance > div .minutes {
  color: #ee411e;
}

.attendance > div .laterHint {
  margin: 0rem 1rem;
}

.raiseHand .content > div {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
}

.raiseHand .content > div > p:first-child {
  margin-right: 1rem;
  display: flex;
}

.raiseHand .content .timestamp {
  display: flex;
  flex-wrap: wrap;
}

.raiseHand .content .timestamp > p {
  padding-right: 1rem;
  margin-right: 1rem;
}

.raiseHand .content .timestamp > div {
  display: flex;
  align-items: center;
}

.raiseHand .content .timestamp > div > div {
  width: 28px;
  height: 28px;
  background-color: #e8e8e8;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
  height: 25px;
}

.raiseHand .content .timestamp > div:not(:last-child) {
  border-right: 1px solid #b3b3b3;
  padding-right: 0.8rem;
  margin-right: 0.8rem;
}

.raiseHand .content .timestampArea {
  align-items: unset;
}

.raiseHand .content > div.timestampArea {
  height: unset;
}

.raiseHand .content .timestampArea .timestamp > div {
  margin-bottom: 0.5rem;
}

/* .raiseHand .content .timestampArea > p {
  margin-top: 0.2rem;
} */

.quiz .content {
  width: 100%;
}

.quiz .tableTitleWrap {
  display: flex;
  color: #1452cc;
  font: normal normal 600 18px/21px InterRegular;
  padding: 1rem 0rem 1rem 0rem;
  border-bottom: 1px solid #e3e3e3;
}

.quiz .tableTitleWrap .largeCol,
.quiz .accordion .largeCol {
  width: calc(100% - 250px);
}

.quiz .tableTitleWrap .smallCol,
.quiz .accordion .smallCol {
  min-width: 250px;
  text-align: center;
}

.quiz .question .smallCol {
  display: flex;
  justify-content: center;
}

.quiz .accordion {
  margin: 1.5rem 0rem;
}

.quiz .summary {
  display: flex;
  width: 100%;
  font: normal normal 600 18px/21px InterRegular;
}

.quiz .summary .quizName {
  display: table;
  table-layout: fixed;
}

.quiz .summary .quizName p {
  padding-right: 1rem;
  display: table-cell;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.quiz .summary .largeCol {
  width: calc(100% - 200px);
}

.quiz .details {
  padding-left: 36px;
}

.quiz .question {
  display: flex;
  align-items: center;
  font: normal normal 600 18px/21px InterRegular;
  background-color: #ebedf099;
  border-radius: 8px;
  padding: 0.5rem 0rem;
  margin: 1rem 0rem 1.5rem 0rem;
}

.quiz .questionContent {
  display: table;
  table-layout: fixed;
}

.quiz .questionContent > p {
  padding-right: 1rem;
  display: table-cell;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.quiz .question .largeCol {
  padding-left: 16px;
  width: calc(100% - 200px);
}

.quiz .question .largeCol > span {
  margin-right: 0.5rem;
}

.quiz .answer {
  padding: 0.3rem 0.7rem;
  border-radius: 5px;
  color: #fff;
  width: 100px;
  display: flex;
  justify-content: center;
}

.quiz .answer.correct {
  background-color: #1452cc;
}

.quiz .answer.correct {
  background-color: #1452cc;
}

.quiz .answer.incorrect {
  background-color: #e60026;
}

.quiz .answer.null {
  background-color: #888888;
}

.quiz .noRecordsFound {
  display: flex;
  align-items: end;
  padding-bottom: 0.5rem;
  margin-top: 1.5rem;
}

.quiz .noRecordsFound > div {
  background-color: #f5f8ff;
  color: #1452cc;
  border: 1px solid #1452cc;
  border-radius: 8px;
  padding: 0.5rem 2rem;
  height: 1.5rem;
  font: normal normal 600 18px/21px InterRegular;
}

.imageContent {
  display: flex;
  align-items: center;
}

.imageContent img {
  height: 25px;
}
