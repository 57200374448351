.card {
  width: 719px;
  min-height: 134px;
  padding: 23px 26px;
  background-color: #fff;
  border-radius: 10px;
}

.titleWrap {
  display: flex;
  justify-content: space-between;
  font: normal normal 600 16px/20px InterRegular;
  margin-bottom: 12px;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.question_people_date {
  display: flex;
  gap: 24px;
  font: normal normal normal 16px/20px InterRegular;
  margin-bottom: 18px;
}

.question_people, .assigned_classrooms {
  display: flex;
  gap: 10px;
  align-items: center;
  font: normal normal normal 16px/20px InterRegular;
}

.assigned_classrooms {
  align-items: flex-start;
}

.assigned_classrooms.center {
  align-items: center;
}

.classrooms {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}

.classroom {
  padding: 6px 9px;
  background-color: #F5F8FF;
  border-radius: 8px;
  font: normal normal normal 14px/17px InterRegular;
}

.menuWrap {
  background-color: #fff;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0px 0px 6px #00000033;
}

.option {
  height: 47px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 15px;
  width: 149px;
  gap: 8px;
}

.option.red {
  color: #E60026;
}

.option.blue:hover {
  background-color: #DCDEE0;
  color: #1452CC;
}

.option.red:hover {
  background-color: #F5052D;
  color: #fff;
}

.moreIcon {
  width: 30px;
  height: 30px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.moreIcon:hover, .moreIcon.active {
  background-color: #E3E3E3;
}

.icon {
  width: 24px;
  height: 24px;
  background-position: center;
  background-repeat: no-repeat;
}

.result {
  background-image: url('../../../assets/icons/quiz/viewResult.svg');
}

.edit {
  background-image: url('../../../assets/icons/quiz/editPen.svg');
}

.duplicate {
  background-image: url('../../../assets/icons/quiz/duplicate.svg');
}

.export {
  background-image: url('../../../assets/icons/quiz/export.svg');
}

.delete {
  background-image: url('../../../assets/icons/quiz/delete.svg');
}

.option:hover .edit {
  background-image: url('../../../assets/icons/quiz/editPen_hover.svg');
}

.option:hover .duplicate {
  background-image: url('../../../assets/icons/quiz/duplicate_hover.svg');
}

.option:hover .export {
  background-image: url('../../../assets/icons/quiz/export_hover.svg');
}

.option:hover .delete {
  background-image: url('../../../assets/icons/quiz/delete_hover.svg');
}

.option:hover .result {
  background-image: url('../../../assets/icons/quiz/viewResult_hover.svg');
}

.emptyHint {
  color: #888888;
  font: normal normal normal 14px/17px InterRegular;
}