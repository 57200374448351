.titleWrap {
  display: grid;
  grid-template-columns: 2.8fr 1.8fr 1fr 1fr 0.7fr;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  gap: 20px;
  width: 100%;
}

.tableItem {
  border-radius: 8px;
  height: 100%;
  background-color: #fff;
  font: normal normal 600 14px/17px InterRegular;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0.5rem 0 0.5rem 1.2rem;
}

.tableItem .status {
  display: flex;
  align-items: center;
}

.noItems {
  background-color: #fff;
  text-align: center;
  padding: 3rem;
  margin-top: 1rem;
  border-radius: 8px;
}

.noItems p {
  color: #808080;
}

p {
  margin: 0;
}

.itemWrap {
  margin-top: 20px;
  background-color: #fff;
  display: grid;
  font: normal normal 600 18px/21px InterRegular;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
  grid-template-columns: 2.8fr 1.8fr 1fr 1fr 0.7fr;
  gap: 20px;
  height: 48px;
  border-radius: 8px;
}

.item {
  border-radius: 8px;
  background-color: #fff;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
  box-sizing: border-box;
  padding: 0.5rem 0rem 0.5rem 1.2rem;
  display: flex;
  align-items: center;
  min-width: 0;
  font: normal normal normal 14px/17px InterRegular;
}

.item.assignmentName {
  font: normal normal 600 14px/17px InterRegular;
}

.item.status {
  justify-content: center;
}

.item.status > p {
  width: 110px;
  height: 30px;
  border-radius: 8px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal normal 14px/17px InterRegular;
}

.status.published > p {
  background-color: #175CE6;
}

.status.draft > p {
  background-color: #AFBBC7;
}

.status.finished > p {
  background-color: #E8E8E8;
  color: #B3B3B3;
}

.item > p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* .courseWrap {
  display: grid;
  grid-template-columns: 3fr 3fr 1.1fr;
  gap: 10px;
} */

/* .course {
  background-color: #e8e8e8;
  padding: 0 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  font: normal normal 600 14px/21px InterRegular;
  font-size: clamp(0.8rem, 0.2em + 0.5vw, 1rem);
  min-width: 38%;
} */

.questionIcon {
  width: 1.3rem;
  height: 1.3rem;
  background-color: #000;
  border-radius: 50%;
  margin-left: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.course p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.paginationWrap {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.searchInputWrap {
  width: 323px;
}

.tableContainer {
  box-sizing: border-box;
  height: calc(100vh - 23px);
  overflow-x: scroll;
}

.more {
  padding: 0;
}

.question {
  display: flex;
  align-items: center;
}

.question img {
  margin-right: 0.5rem;
}

.updatedDate img {
  margin-left: 1rem;
  cursor: pointer;
}

.option {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 15px 30px 15px 15px;
  user-select: none;
  cursor: pointer;
}

.option.disabled {
  color: #B3B3B3;
  pointer-events: none;
}

.option.delete {
  color: #E60026;
}

.option:hover {
  background-color: #DCDEE0;
  color: #1452CC;
}

.option.delete:hover {
  background-color: #E60026;
  color: #fff;
}

.optionIcon {
  background-repeat: no-repeat;
  background-position: center;
  width: 24px;
  height: 24px;
}

.editIcon {
  background-image: url('../../../assets/icons/quiz/editPen.svg');
}

.option:hover .editIcon {
  background-image: url('../../../assets/icons/quiz/editPen_hover.svg');
}

.duplicateIcon {
  background-image: url('../../../assets/icons/quiz/duplicate.svg');
}

.option:hover .duplicateIcon {
  background-image: url('../../../assets/icons/quiz/duplicate_hover.svg');
}

.deleteIcon {
  background-image: url('../../../assets/icons/quiz/delete.svg');
}

.option:hover .deleteIcon {
  background-image: url('../../../assets/icons/quiz/delete_hover.svg');
}

.publishIcon {
  background-image: url('../../../assets/icons/quiz/publish.svg');
}

.option:hover .publishIcon {
  background-image: url('../../../assets/icons/quiz/publish_hover.svg');
}

.option.disabled .publishIcon {
  background-image: url('../../../assets/icons/quiz/publish_disabled.svg');
}

.endIcon {
  background-image: url('../../../assets/icons/quiz/end.svg');
}

.option:hover .endIcon {
  background-image: url('../../../assets/icons/quiz/end_hover.svg');
}

.resultsIcon {
  background-image: url('../../../assets/icons/quiz/results.svg');
}

.option:hover .resultsIcon {
  background-image: url('../../../assets/icons/quiz/results_hover.svg');
}

.item.more {
  display: flex;
  justify-content: center;
  align-items: center;
}

.actionIcon {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

.actionIcon:hover, .actionIcon.active {
  background-color: #E3E3E3;
  cursor: pointer;
}

@media screen and (max-width: 1600px) {
  .titleWrap,
  .itemWrap {
    grid-template-columns: 2fr 1.8fr 1fr 1.3fr 0.7fr;
  }
}
