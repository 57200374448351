.utilityRowWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
  /* width: 100%; */
}

.utilityRowWrap .left {
  display: flex;
  /* flex: 1; */
  align-items: center;
  font: normal normal 600 24px/29px InterRegular;
  font-size: clamp(1.4rem, 0.4em + 0.8vw, 1.6rem);
}

.utilityRowWrap .left .leftWrap {
  display: flex;
  align-items: center;
}

.utilityRowWrap .left > div:first-child {
  margin-right: 2rem;
}

.utilityRowWrap .right {
  /* flex: 2; */
  display: flex;
  gap: 24px;
  align-items: center;
}

.utilityRowWrap .left .questionIcon,
.tableItem .questionIcon {
  width: 1.3rem;
  height: 1.3rem;
  background-color: #000;
  border-radius: 50%;
  margin-left: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchInputWrap {
  width: 323px;
}

.addBtn {
  min-width: 190px;
  height: 60px;
  background-color: #175ce6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  cursor: pointer;
  border-radius: 8px;
  font: normal normal 600 18px/21px InterLight;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
  border: none;
  box-sizing: border-box;
  padding: 0 21px;
}

.addBtn.small {
  min-width: 144px;
}

.menuOption {
  width: 169px;
  height: 48px;
  cursor: pointer;
  font: normal normal 600 14px/17px InterRegular;
  font-size: clamp(0.8rem, 0.3rem + 0.4vw, 1.3rem);
  display: flex;
  align-items: center;
  padding-left: 12px;
}

.menuOption:hover {
  background-color: #f3f3f3;
  color: #1452cc;
}

.resultBtn {
  min-width: 114px;
  height: 60px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #175ce6;
  border: 1px solid #175ce6;
  cursor: pointer;
  border-radius: 8px;
  font: normal normal 600 18px/21px InterLight;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
  box-sizing: border-box;
}

.resultBtn:hover {
  background-color: #175CE6;
  color: #fff;
}

.resultBtn:active {
  background-color: #1142A6;
}

.resultIcon {
  background-image: url('../../../assets/icons/quiz/result.svg');
  background-position: center;
  background-repeat: no-repeat;
  width: 19px;
  height: 19px;
}

.resultBtn:hover .resultIcon {
  background-image: url('../../../assets/icons/quiz/result_hover.svg');
}

.resultBtn.disabled .resultIcon {
  background-image: url('../../../assets/icons/quiz/result_disabled.svg');
}

.ltiTag {
  width: 52px;
  height: 25px;
  background-color: #1452CC;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font: normal normal 600 14px/17px InterRegular;
  color: #FFFFFF;
  margin-left: 12px;
}

@media (max-width: 1810px) {
  .utilityRowWrap {
    display: block;
  }

  .utilityRowWrap .right {
    margin-top: 12px;
  }
}