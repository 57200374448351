.breadCrumbs {
  display: flex;
  gap: 24px;
  align-items: center;
  margin-bottom: 1.5rem;
}

.icon {
  cursor: pointer;
}

.breadCrumbs .tab {
  font: normal normal 600 24px/29px InterRegular;
  font-size: clamp(1.4rem, 0.4em + 0.8vw, 1.6rem);
}

.clickable {
  cursor: pointer;
}

.container {
  background-color: rgba(235, 237, 240, 0.4);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem;
}

.contentWrap {
  width: 38rem;
  background-color: #fff;
  border-radius: 8px;
  padding: 36px;
}

.titleWrap {
  display: flex;
  align-items: center;
  margin: 1rem 0rem;
}

.questionTypeIcon {
  border: 2px solid #1452cc;
  border-radius: 4px;
  color: #1452cc;
  padding: 0.3rem 1rem;
  margin-right: 1rem;
}

.titleWrap > p,
.optionsWrap > p {
  font: normal normal 600 18px/21px InterRegular;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
}

.options {
  margin-left: 0.5rem;
}

.optionsWrap {
  margin: 2rem 0rem 1.5rem 0rem;
}

.contentWrap > p {
  font: normal normal normal 18px/24px InterRegular;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
}

.image {
  margin-top: 1rem;
  max-width: 100%;
  height: 250px;
}

.trueFalseOptions,
.singleSelection,
.multipleSelection {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.trueFalseOptions img,
.singleSelection img,
.multipleSelection img {
  margin-right: 0.7rem;
}

.backBtn {
  margin-left: auto;
  width: 162px;
  height: 48px;
  border-radius: 8px;
  cursor: pointer;
  background-color: #f5f8ff;
  color: #1452cc;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #1452cc;
  font: normal normal 600 18px/21px InterRegular;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
}

.backBtn > img,
.backBtn > p {
  margin-right: 0.5rem;
}

.arrowWrap {
  display: flex;
  justify-content: center;
}

.arrowWrap div {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  background-color: #fff;
  border-radius: 8px;
  padding: 0.5rem;
  margin: 2rem 0.5rem;
}

.arrowWrap div.disabled {
  opacity: 0.3;
  cursor: unset;
}
