.modalWrap {
  width: 960px;
  height: 430px;
  background-color: #fff;
  border-radius: 8px;
  box-sizing: border-box;
  position: relative;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

.close {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}