.modalWrap {
    width: 600px;
    height: 300px;
    background-color: #fff;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 41px 47px 38px 47px;
  }
  
  .title {
    margin-bottom: 21px;
    font: normal normal 600 36px/44px InterBold;
  }
  
  .message {
    margin-bottom: 87px;
    font: normal normal normal 18px/21px InterLight;
  }
  
  .btnWrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .btn {
    width: 144px;
    height: 48px;
    background-color: #FFFFFF;
    font: normal normal normal 14px/36px InterRegular;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .remove {
    border: 2px solid #1452CC;
    color: #1452CC;
    margin-right: 24px;
  }
  
  .cancel {
    border: 2px solid #707070;
    color: #000000;
  }

  .no {
    border: 2px solid #B3B3B3;
    color: #B3B3B3;
    margin-right: 24px;
  }