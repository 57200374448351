body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*font css*/

@font-face {
  font-family: "InterBlack";
  src: url('./assets/fonts/Inter-Black.ttf') format("truetype");
}

@font-face {
  font-family: "InterBold";
  src: url('./assets/fonts/Inter-Bold.ttf') format("truetype");
}

@font-face {
  font-family: "InterExtraBold";
  src: url('./assets/fonts/Inter-ExtraBold.ttf') format("truetype");
}

@font-face {
  font-family: "InterExtraLight";
  src: url('./assets/fonts/Inter-ExtraLight.ttf') format("truetype");
}

@font-face {
  font-family: "InterLight";
  src: url('./assets/fonts/Inter-Light.ttf') format("truetype");
}

@font-face {
  font-family: "InterMedium";
  src: url('./assets/fonts/Inter-Medium.ttf') format("truetype");
}

@font-face {
  font-family: "InterRegular";
  src: url('./assets/fonts/Inter-Regular.ttf') format("truetype");
}

@font-face {
  font-family: "InterSemiBold";
  src: url('./assets/fonts/Inter-SemiBold.ttf') format("truetype");
}

@font-face {
  font-family: "InterThin";
  src: url('./assets/fonts/Inter-Thin.ttf') format("truetype");
}

/*modal css*/

#modal {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
}

#modal:empty {
  display: none;
}

#modal > div {
  background-color: white;
  border-radius: 10px;
}

#modal .buttons button {
  display: inline-block;
  margin-right: 15px;
}

/* scrollbar css */

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey;  */
  /* border-radius: 10px; */
  background-color: transparent;
  /* display: none; */
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #dcdee0; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #dcdee0; 
}

html {
  font-size: 100%;
}
