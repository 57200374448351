.wrap {
  width: 70vw;
  height: 70vh;
  min-width: 1090px;
  height: 850px;
  background-color: #fff;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 60px;
}

.titleWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.titleWrap .title {
  font: normal normal 600 48px/59px InterMedium;
}

.titleWrap .cancel {
  cursor: pointer;
  transform: scale(2);
}

.utilityWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
}

.utilityWrap .select {
  width: 331px;
}

.utilityWrap .right {
  display: flex;
  align-items: center;
  gap: 5px;
}

.utilityWrap .right .text {
  font: normal normal 600 18px/21px InterRegular;
}

.utilityWrap .right .num {
  font: normal normal normal 18px/21px InterRegular;
  color: #1452CC;
}

.contentWrap {
  width: 100%;
  height: 476px;
  overflow-y: scroll;
  box-sizing: border-box;
  padding: 36px 24px;
  background-color: #F5F8FF;
  border: 1px solid #707070;
  border-radius: 16px;
  margin-top: 12px;
}

.importRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 36px;
}

.importRow .left {
  display: flex;
  align-items: center;
  gap: 37px;
  font: normal normal 600 18px/21px InterRegular;
}

.slotWrap {
  display: flex;
  align-items: center;
  gap: 36px;
}

.slotItem {
  display: flex;
  align-items: center;
  font: normal normal 600 18px/21px InterMedium;
}

.slotItem .slot {
  color: #0056FF;
  font: normal normal normal 18px/21px InterMedium;
}

.slotItem .slot.warning {
  color:#FF0000;
}

.import {
  background-color: #175CE6;
  color: #fff;
  cursor: pointer;
  width: 340px;
  height: 60px;
  border-radius: 8px;
  border: none;
  font: normal normal normal 24px/29px InterRegular;
  align-self: center;
}

.import:active {
  background-color: #1142A6;
}

.import.disabled {
  background-color: #E3E3E3;
  cursor: not-allowed;
}

.tableWrap {
  display: flex;
  align-items: center;
  height: 48px;
}

.tableWrap .checkbox {
  width: 48px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tableWrap .right {
  width: calc(100% - 48px);
  height: 100%;
  display: flex;
  align-items: center;
}

.tableWrap .right .role {
  width: calc(100% / 9 * 1);
  padding-left: 0.5%;
  box-sizing: border-box;
}

.tableWrap .right .name {
  width: calc(100% / 9 * 3);
  padding-left: 0.5%;
  box-sizing: border-box;
}

.tableWrap .right .email {
  width: calc(100% / 9 * 5);
  padding-left: 0.5%;
  box-sizing: border-box;
}

.tableWrap.tableContent {
  margin-top: 20px;
  background-color: #fff;
  border-radius: 8px;
}

.tableWrap.tableContent.disabled {
  background-color: #E3E3E3;
}

.tableWrap.tableHeader .right {
  background-color: #fff;
  border-radius: 8px;
}

.tableItem {
  font: normal normal 600 18px/23px InterRegular;
}

.loadingWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #0000008A;
  z-index: 10;
}



