.addStudentModalWrap {
  width: 70vw;
  height: 70vh;
  min-width: 1610px;
  height: 850px;
  background-color: #fff;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 60px;
  display: flex;
  flex-direction: column;
}

.privacy {
  filter: blur(4px);
  pointer-events: none;
  user-select: none;
}

.modalTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font: normal normal 600 48px/59px InterSemiBold;
}

.title {
  font: normal normal 600 48px/59px InterBold;
}

.cancel {
  cursor: pointer;
  transform: scale(2);
}

.contentWrap {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  height: 100%;
  margin-top: 24px; 
}

.contentWrap .left, .contentWrap .right {
  flex: 1;
  height: 100%;
}

.contentWrap .left .content, .contentWrap .right .content {
  border: 1px solid #707070;
  border-radius: 16px;
  height: 520px;
  box-sizing: border-box;
  padding: 22px;
  overflow-y: scroll;
}

.contentWrap .left .content.over, .contentWrap .right .content.over {
  background-color: #ccdbff;
}

.contentWrap .left .content::-webkit-scrollbar, .contentWrap .right .content::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.contentWrap .right .content {
  background-color: #F5F8FF;
}

.contentWrap .left .titleWrap, .contentWrap .right .titleWrap {
  display: flex;
  font: normal normal 600 18px/21px InterRegular;
  margin-bottom: 12px;
}

.contentWrap .right .titleWrap {
  justify-content: space-between;
}

.draggableItemsWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 26px;
}

.subtitle {
  margin-top: 24px;
  margin-bottom: 12px;
  font: normal normal 600 18px/21px InterRegular;
}

.draggableItem {
  width: 310px;
  /* height: 36px; */
  background: #FFF;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* box-sizing: border-box; */
  padding: 10px;
  line-height: 1.5;
}

.draggableItem .left {
  display: flex;
  align-items: center;
}

.dragHandler {
  cursor: move;
  margin-top: 5px;
}

.icon {
  cursor: pointer;
}

.loadingWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #0000008A;
  z-index: 10;
}

.save {
  background-color: #175CE6;
  color: #fff;
  cursor: pointer;
  width: 240px;
  height: 80px;
  border-radius: 8px;
  border: none;
  font: normal normal normal 24px/29px InterRegular;
  align-self: flex-end;
}

.save:active {
  background-color: #1142A6;
}

.save.disabled {
  background-color: #E3E3E3;
  cursor: not-allowed;
}

.right .titleWrap .warning {
  color: #ff0000;
}