.modalWrap {
  width: 600px;
  height: 300px;
  background-color: #fff;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 40px;
}

.title {
  margin-bottom: 21px;
  font: normal normal 600 24px/29px InterRegular;
}

.message {
  margin-bottom: 87px;
  font: normal normal normal 18px/36px InterRegular;
}

.btnWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.btn {
  width: 144px;
  height: 48px;
  background: #ffffff 0% 0% no-repeat padding-box;
  font: normal normal normal 14px/17px InterRegular;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.signOut {
  border: 2px solid #f10000;
  color: #f10000;
  margin-right: 24px;
}

.signOut:hover {
  background-color: #f5052d;
  color: #fff;
}

.signOut:active {
  border-color: #cc0022;
  background-color: #cc0022;
}

.cancel {
  border: 2px solid #707070;
  border-radius: 8px;
  color: #888888;
}

.cancel:active {
  border-color: #808080;
  background-color: #808080;
  color: #fff;
}

.cancel:hover {
  border-color: #b3b3b3;
  background-color: #b3b3b3;
  color: #fff;
}
