.thirdRow {
  display: flex;
  min-height: 400px;
  margin-top: 24px;
}

.thirdRow.scroll {
  height: 400px;
}

.tableContainer {
  margin-left: 8px;
  width: 100%;
  /* border: 1px solid cyan; */
  overflow-y: scroll;
  height: 90%;
}

.tableContainer::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.GroupUpWrap {
  width: 100%;
  background-color: #FFF;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 36px;
}

.titleWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.titleWrap .left, .titleWrap .right {
  display: flex;
  align-items: center;
  gap: 24px;
}

.title {
  font: normal normal 600 24px/29px InterRegular;
  font-size: clamp(1.4rem, 0.4em + 1vw, 1.6rem);
}

.icon {
  cursor: pointer;
}

.square {
  width: 48px;
  height: 48px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.square:hover {
  background-color: #c0c0c0;
  opacity: 0.6;
}

.tableWrap {
  display: flex;
  align-items: center;
  gap: 5px;
  box-sizing: border-box;
  padding: 14px;
  min-height: 55px;
  border-bottom: 1px solid #E3E3E3;
}

.tableWrap.tableHeader {
  background-color: rgb(255, 179, 179);
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 1;
}

.tableWrap.tableContent:hover {
  background-color: #dddddd;
}

.tableWrap .tableItem {
  /* background-color: rgb(213, 236, 255); */
  box-sizing: border-box;
  font: normal normal normal 18px/21px InterRegular;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
  cursor: pointer;
  /* padding: 14px;
  min-height: 55px; */
}

.tableWrap .groupUpName {
  width: calc(100% / 12 * 6);
  display: flex;
  align-items: center;
  gap: 12px;
}

.tableWrap .group, .tableWrap .pergroup, .tableWrap .duplicate, .tableWrap .delete {
  width: calc(100% / 12 * 1);
  display: flex;
  justify-content: center;
}

.tableWrap .unassigned {
  width: calc(100% / 12 * 2);
  display: flex;
  justify-content: center;
}

.btn {
  width: 144px;
  height: 48px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font: normal normal normal 14px/17px InterRegular;
  cursor: pointer;
}

.remove {
  border: 1px solid #FF0000;
  color: #FF0000;
}

.remove:hover {
  background-color: #E60026;
  color: #fff;
}

.remove.disabled {
  border: none;
  color: #fff;
}

.remove:active {
  background-color: #CC0022;
  color: #fff;
}

.save {
  background-color: #175CE6;
  color: #fff;
}

.remove.disabled, .save.disabled {
  background-color: #E3E3E3;
  cursor: not-allowed;
}

.remove.disabled:hover, .save.disabled:hover {
  background-color: #E3E3E3;
  cursor: not-allowed;
}

.remove.disabled:hover, .save.disabled:active {
  background-color: #E3E3E3;
  cursor: not-allowed;
}

.save:hover {
  background-color: #175CE6;
  color: #fff;
}

.save:active {
  background-color: #1142A6;
  color: #fff;
}

.radioGroupBg {
  margin-top: 35px;
  background-color: #F5F8FF;
  width: 684px;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 27px;
  display: flex;
  flex-direction: column;
}

.divideline {
  border-top: 1px solid #B3B3B3;
  margin-top: 24px;
}

.assignArea {
  display: flex;
  margin-top: 24px;
}

.assignArea .left {
  flex: 3;
}

.assignArea .left .boxes, .assignArea .right .dragBoxes {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  margin-top: 12px;
}

.dragBoxesWrap {
  /* border: 1px solid blueviolet;s */
  min-height: 100%;
}

.assignArea .right {
  flex: 1;
}

.groupBox {
  border: 1px solid #B3B3B3;
  border-radius: 8px;
  width: 336px;
  height: 254px;
  display: flex;
}

.groupBox.bigger {
  width: 500px;
}

.groupBox .index {
  width: 100px;
  height: 100%;
  background-color: #F5F8FF;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px 0px 0px 8px;
}

.groupBox .drop {
  box-sizing: border-box;
  padding-left: 11px;
  padding-top: 11px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 12px;
}

.hintText {
  display: flex;
  justify-content: center;
  font-size: clamp(0.8rem, 0.2em + 0.5vw, 1rem);
  font: normal normal normal 14px/17px InterRegular;
  color: #888888;
  margin-top: 43px;
}

.searchBar {
  width: 1035px;
  margin-bottom: 10px;
}

.subtitle {
  font: normal normal 600 14px/17px InterRegular;
  font-size: clamp(0.8rem, 0.2em + 0.5vw, 1rem);
}

.groupBox .drop .item, .dragBox {
  width: 174px;
  height: 36px;
  border: 1px solid #DCDEE0;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dragBox {
  word-break: break-all;
}

.dragBox.highlighted {
  background-color: rgb(251, 255, 0);
}

.dragBox.isOver {
  background-color: #E3E3E3;
}

.groupBox.clickable {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.draft {
  font: normal normal normal 14px/17px InterRegular;
  color: #1452CC;
  cursor: pointer;
}

.draft.disabled {
  visibility: hidden;
  pointer-events: none;
}

.update {
  background-color: #175CE6;
  color: #fff;
  cursor: pointer;
  width: 162px;
  height: 48px;
  border-radius: 8px;
  border: none;
  font: normal normal 600 18px/21px InterRegular;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
}

.update:active {
  background-color: #1142A6;
}

.update.disabled {
  background-color: #E3E3E3;
  /* cursor: not-allowed; */
  pointer-events: none;
}