.modalWrap {
  width: 60vw;
  max-height: 75vh;
  background-color: #fff;
  border-radius: 8px;
  box-sizing: border-box;
  overflow: scroll;
}

.titleArea {
  box-shadow: 0px 3px 6px #00000029;
  z-index: 1;
  padding: 2.5rem 3rem 1.5rem 3rem;
}

.titleArea .title {
  display: flex;
  justify-content: space-between;
}

.titleArea .cancel {
  cursor: pointer;
  color: #000000;
}

.titleArea .title > h1 {
  margin: 0rem;
  font: normal normal 600 24px/60px InterMedium;
}

.titleArea .detail {
  display: flex;
}

.titleArea .detail > div {
  display: flex;
}

.titleArea .detail > div img {
  margin-right: 0.7rem;
}

.titleArea .detail > div:first-child {
  margin-right: 1rem;
}

.titleArea .detail > div:first-child img {
  width: 14px;
}

.titleArea .detail,
.titleArea .timer {
  margin-bottom: 0.7rem;
}

.titleArea .timer {
  display: flex;
}

.titleArea .timer img {
  margin-right: 0.7rem;
}

.questionArea {
  padding: 2.5rem 3rem;
  font: normal normal 400 18px/21px InterRegular;
}

.questionArea .questionType {
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
}

.questionArea .questionType > p {
  margin-right: 1rem;
}

.questionArea .questionTypeIcon {
  border: 2px solid #1452cc;
  border-radius: 4px;
  color: #1452cc;
  padding: 0.3rem 1rem;
  margin-right: 1rem;
}

.questionArea .optionArea {
  height: 30vh;
  overflow-y: scroll;
  padding-right: 0.5rem;
}

.questionArea .option {
  background-color: #e8e9eb99;
  padding: 0.7rem;
  border-radius: 8px;
  margin: 1.5rem 0rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.questionArea .check {
  background-color: #009135;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
}

.questionArea .paginator {
  display: flex;
  align-items: center;
  justify-content: center;
}

.questionArea .paginator span {
  margin: 0.5rem;
  font: normal normal 400 18px/21px InterRegular;
}

.questionArea .paginator img {
  margin: 0rem 2rem;
  cursor: pointer;
}

.questionArea .paginator .disabled {
  opacity: 0.3;
  cursor: unset;
}

.questionArea .questionImage {
  margin-top: 0.5rem;
  max-height: 15rem;
}
