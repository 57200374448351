.linkInput {
  border: none;
  outline: none;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  text-decoration: underline;
  color: #1452CC;
  font: normal normal normal 14px/17px InterRegular;
  cursor: pointer;
  caret-color: transparent;
  /* hide caret(flashing vertical line)!!! */
}

.monthInput {
  border: none;
  outline: none;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  text-decoration: underline;
  cursor: pointer;
  caret-color: transparent;
  font: normal normal 600 24px/29px InterRegular;
  background-color: transparent;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}