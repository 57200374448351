.UsersWrap {
  background-color: #f5f8ff;
  min-height: calc(100vh - 72px);
  min-width: calc(100vw - 240px);
  max-width: calc(100vw - 240px);
  overflow-y: scroll;
  box-sizing: border-box;
  padding: 62px 52px;
  font-size: 100%;
}

.tabsWrap {
  display: flex;
  align-items: center;
  gap: 36px;
  margin-bottom: 24px;
}

.tabWrap.hide {
  display: none;
}

.tab {
  font: normal normal 600 18px/21px InterRegular;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
  cursor: pointer;
  user-select: none;
}

.tab:hover {
  color: #1452cc;
}

.bottomLine {
  width: 100%;
  height: 5px;
  margin-top: 6px;
}

.tab.active {
  color: #1452cc;
}

.tab.active + .bottomLine {
  background-color: #1452cc;
}
