.forbiddenWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #F5F8FF;
  height: calc(100vh - 72px);
  font: normal normal 600 20px/30px InterRegular;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
  gap: 30px;
}

.textWrap {
  padding: 50px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #b3b3b3;
}

.link {
  color: royalblue;
  cursor: pointer;
  text-decoration: underline;
}