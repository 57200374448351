.clickable {
  cursor: pointer;
}

.breadCrumbs {
  display: flex;
  gap: 24px;
  align-items: center;
  margin-bottom: 1.5rem;
}

.pointer {
  cursor: pointer;
}

.breadCrumbs .tab {
  font: normal normal 600 24px/29px InterRegular;
  font-size: clamp(1.4rem, 0.4em + 0.8vw, 1.6rem);
}

.titleArea {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  padding: 1rem 3rem 0rem 3rem;
  border-radius: 5px 5px 0px 0px;
  box-shadow: 0px 3px 6px #00000029;
  position: relative;
  z-index: 2;
  min-height: 15vh;
}

.titleArea > div > .timeArea {
  display: flex;
}

.titleArea > div > .timeArea > p {
  font: normal normal 500 14px/17px InterRegular;
  margin-left: 0.7rem;
}

.titleArea .tabArea {
  margin-top: 2rem;
  display: flex;
}

.titleArea .tabArea .buttonArea {
  display: flex;
  gap: 83px;
}

.titleArea .tabArea .buttonArea button {
  background-color: #fff;
  border: none;
  font: normal normal 600 18px/21px InterRegular;
  border-bottom: 5px solid transparent;
  padding: 1rem 1.5rem 1rem 1.5rem;
  cursor: pointer;
}

.titleArea .tabArea .buttonArea .active {
  border-bottom: 5px solid #1452cc;
}

.titleArea .tabArea .descriptionArea {
  margin-left: auto;
  display: flex;
  margin-right: 3rem;
}

.titleArea .tabArea .descriptionArea .correct,
.titleArea .tabArea .descriptionArea .incorrect {
  margin-right: 2rem;
}

.titleArea .tabArea .descriptionArea > div {
  display: flex;
  align-items: center;
}

.titleArea .tabArea .descriptionArea > div > div {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  margin-right: 0.5rem;
}

.titleArea .tabArea .descriptionArea > div > p {
  font: normal normal 500 18px/21px InterRegular;
}

.titleArea .tabArea .descriptionArea > div.correct > div {
  background-color: #009135;
}

.titleArea .tabArea .descriptionArea > div.incorrect > div {
  background-color: #e60026;
}

.classroom {
  height: 14px;
  width: fit-content;
  font: normal normal normal 14px/17px InterRegular;
  padding: 8px;
  background-color: #E8E8E8;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}