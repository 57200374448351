.wrap {
  width: 800px;
  height: 300px;
  background-color: #fff;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 40px 50px 0 50px;
  display: flex;
  flex-direction: column;
}

.modalTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font: normal normal bold 36px/44px InterSemiBold;
}

.close {
  cursor: pointer;
  transform: scale(2);
}

.btnWrap {
  display: flex;
  justify-content: flex-end;
  gap: 24px;
  margin-top: 80px;
}

.btn {
  width: 224px;
  height: 48px;
  border-radius: 8px;
  font: normal normal normal 24px/29px InterRegular;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  user-select: none;
}

.disallow {
  border: 2px solid #1452CC;
  color: #1452CC;
}

.disallow:hover {
  background-color: #175CE6;
  color: #fff;
}

.disallow:active {
  background-color: #1142A6;
}

.cancel {
  border: 2px solid #707070;
  color: #888888;
}

.cancel:hover {
  background-color: #707070;
  color: #fff;
}

.cancel:active {
  background-color: #808080;
}

.content {
  margin-top: 30px;
  font: normal normal normal 18px/21px InterRegular;
}