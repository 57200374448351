.settingWrap {
  width: 100%;
  min-width: 1450px;
  min-height: 100vh;
  background-color: #f5f8ff;
  box-sizing: border-box;
  padding: 45px 67px 45px 45px;
}

.config {
  width: 100%;
  margin-top: 30px;
}

.config .title {
  background-color: #fff;
  width: 100%;
  height: 137px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 16px 16px 0px 0px;
  box-sizing: border-box;
  padding: 45px 45px 0 45px;
  position: relative;
  z-index: 1;
}

.tabs {
  display: flex;
  align-items: center;
  gap: 28px;
}

.tab {
  padding: 16px 22px;
  font: normal normal 600 18px/21px InterRegular;
  border-bottom: 6px solid transparent;
  cursor: pointer;
}

.tab.active {
  border-bottom: 6px solid #1452cc;
}

.config .content {
  width: 100%;
  min-height: 523px;
  background-color: #fff;
  padding: 45px 45px 0 45px;
  box-sizing: border-box;
}

.switches {
  display: flex;
  flex-direction: column;
  gap: 36px;
}

.switch {
  display: flex;
  align-items: center;
  gap: 24px;
}

.switchTitle {
  font: normal normal 600 18px/27px InterRegular;
}

.switchComment {
  font: normal normal normal 14px/26px InterMedium;
  color: #808080;
}

.instanceListWrap {
  margin-top: 50px;
}

.utilityWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn {
  background-color: #1452cc;
  color: #fff;
  cursor: pointer;
  width: 144px;
  height: 48px;
  border-radius: 8px;
  border: none;
  font: normal normal medium 14px/17px InterMedium;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
}

.btn:hover {
  background-color: #175ce6;
}

.btn:active {
  background-color: #1142a6;
}

.btn.disabled {
  background-color: #dcdee0;
  pointer-events: none;
}

.tableHeader,
.tableRows {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px 0;
  min-height: 49px;
}

.noRecord {
  font: normal normal normal 18px/21px InterRegular;
  color: #808080;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
}

.tableHeader {
  border-bottom: 1px solid #dddddd;
}

.tableContent {
  display: flex;
  align-items: center;
  height: 100%;
  font: normal normal 600 14px/17px InterRegular;
  box-sizing: border-box;
  padding-left: 10px;
}

.typ,
.action {
  width: calc(100% / 16);
}

.note {
  width: calc(100% / 16 * 3);
  word-break: break-all;
}

.d_id,
.c_id {
  width: calc(100% / 16 * 3);
  word-break: break-all;
}

.domain {
  width: calc(100% / 16 * 5);
  word-break: break-all;
}

.paginationWrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.menuWrap {
  background-color: #fff;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0px 0px 6px #00000033;
}

.option {
  height: 47px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 15px 22px 15px 15px;
}

.edit:hover {
  color: #1452cc;
}

.delete:hover {
  color: #e60026;
}
