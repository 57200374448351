.btn {
  background-color: #1452cc;
  color: #fff;
  cursor: pointer;
  min-width: 144px;
  min-height: 48px;
  padding: 15px 56px;
  border-radius: 8px;
  border: none;
  font: normal normal normal 14px/17px InterRegular;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
}

.btn.blue:hover {
  background-color: #175ce6;
}

.btn.blue:active {
  background-color: #1142a6;
}

.btn.blue.disabled {
  background-color: #dcdee0;
  pointer-events: none;
}
