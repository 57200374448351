.utilityRowWrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
}

.left {
  display: flex;
  gap: 25px;
  font: normal normal normal 24px/29px InterMedium;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 192px;
  height: 48px;
  border: 2px solid #1452cc;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  background-color: transparent;
  color: #1452cc;
}

.btn:hover {
  background-color: #175CE6;
  color: #fff;
}

.btn:active {
  background-color: #1142A6;
  color: #fff;
}

.btn.disabled {
  border-color: #B3B3B3;
  color: #B3B3B3;
}

.tableContainer {
  overflow-x: scroll;
  height: calc(100vh - 180px);
}

.tableWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  height: 48px;
  min-width: 1200px;
}

.tableWrap.tableContent {
  background-color: #fff;
  border-radius: 8px;
  margin-top: 20px;
}

.tableItem {
  background: #fff;
  border-radius: 8px;
  height: 100%;
  display: flex;
  align-items: center;
  font: normal normal 600 14px/17px InterRegular;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
  box-sizing: border-box;
  padding-left: 0.5%;
}

.tableItem.action, .tableItem.status, .tableItem.question, .tableItem.participant {
  width: calc(100% / 12 * 1);
}

.tableItem.time, .tableItem.name {
  width: calc(100% / 12 * 4);
}

.tableWrap.center .status, .tableWrap.center .action {
  padding-left: 0;
  justify-content: center;
}

.tableContent .tableItem.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  padding-top: 13px;
}

/* .tableContent .tableItem.status {
  height: 30px;
  border-radius: 8px;
  font: normal normal normal 14px/17px InterRegular;
} */

.published {
  height: 30px;
  border-radius: 8px;
  font: normal normal normal 14px/17px InterRegular;
  width: 112px;
  height: 30px;
  background-color: #E8E8E8;
  color: #B3B3B3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.finished {
  background-color: #E8E8E8;
  color: #B3B3B3;
}

.tableContent .tableItem.question, .tableContent .tableItem.participant {
  display: flex;
  gap: 10px;
}

.actionBox {
  width: 30px;
  height: 30px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.actionBox:hover, .actionBox.active {
  background-color: #E3E3E3;
}

.menuWrap {
  background-color: #fff;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0px 0px 6px #00000033;
}

.option {
  height: 47px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  box-sizing: border-box;
  padding: 10px;
  width: 152px;
  gap: 5px;
}

.option.blue:hover {
  background-color: #DCDEE0;
  color: #1452CC;
}

.option.red {
  color: #F5052D;
}

.option.red:hover {
  background-color: #F5052D;
  color: #fff;
}

.icon {
  width: 30px;
  height: 30px;
  background-position: center;
  background-repeat: no-repeat;
}

.resultIcon {
  background-image: url('../../../assets/icons/quiz/viewResult.svg');
}

.deleteIcon {
  background-image: url('../../../assets/icons/quiz/delete.svg');
}

.option.blue:hover .resultIcon {
  background-image: url('../../../assets/icons/quiz/viewResult_hover.svg');
}

.option.red:hover .deleteIcon {
  background-image: url('../../../assets/icons/quiz/delete_hover.svg');
}

.statusHint {
  width: 18px;
  height: 18px;
  background-color: #000;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
}

.paginationWrap {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.empty {
  background-color: #fff;
  width: 100%;
  height: 112px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font: normal normal normal 16px/21px Inter;
  color: #808080;
  border-radius: 8px;
  gap: 10px;
  margin-top: 10px;
}