.radioWrap {
  display: flex;
  align-items: flex-start;
}

.selectWrap {
  display: flex;
  align-items: center;
  gap: 12px;
}

.text {
  margin-top: -10px;
  font: normal normal 600 14px/17px InterRegular;
}

.text.disabled {
  opacity: 0.6;
}

.select.disabled {
  pointer-events: none;
}

.regroup {
  background-color: transparent;
  color: #1452CC;
  cursor: pointer;
  width: 83px;
  height: 24px;
  border: none;
  font: normal normal 600 14px/17px InterRegular;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  gap: 10px;
}

.regroup:active {
  color: #adadad;
}

.regroup.disabled {
  color: #3a3a3a;
  opacity: 0.6;
  pointer-events: none;
}