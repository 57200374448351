.StudentListWrap {
  width: 100%;
  background-color: #FFF;
  border-radius: 8px;
  height: 100%;
  box-sizing: border-box;
  padding: 36px;
}

.titleWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font: normal normal 600 24px/29px InterRegular;
  font-size: clamp(1.4rem, 0.4em + 1vw, 1.6rem);
}

.StudentListWrap .titleWrap .left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.studentList {
  display: flex;
  align-items: center;
  font: normal normal normal 18px/36px InterRegular;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
  color: #000000;
}

.hintText {
  display: flex;
  justify-content: center;
  font-size: clamp(0.8rem, 0.2em + 0.5vw, 1rem);
  font: normal normal normal 14px/17px InterRegular;
  color: #888888;
  margin-top: 43px;
}

.square {
  width: 48px;
  height: 48px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.square:hover {
  background-color: #c0c0c0;
  opacity: 0.6;
}

.tableContainer {
  margin-left: 8px;
  width: 100%;
  /* border: 1px solid cyan; */
  overflow-y: scroll;
  height: 90%;
}

.tableContainer::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.tableWrap {
  display: flex;
  align-items: center;
  gap: 5px;
  box-sizing: border-box;
  padding: 14px;
  min-height: 55px;
  border-bottom: 1px solid #E3E3E3;
}

.privacy {
  filter: blur(4px);
  pointer-events: none;
  user-select: none;
}

.tableWrap.tableHeader {
  /* background-color: rgb(255, 179, 179); */
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 1;
}

.tableWrap .tableItem {
  /* background-color: rgb(213, 236, 255); */
  box-sizing: border-box;
  font: normal normal normal 18px/21px InterRegular;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
  /* padding: 14px;
  min-height: 55px; */
}

/* .tableWrap .checkbox {
  width: calc(100% / 20 * 1);
} */

.tableWrap .status, .tableWrap .action {
  width: calc(100% / 19 * 2);
  display: flex;
  justify-content: center;
}

.tableContent .action {
  cursor: pointer;
}

.tableWrap .name, .tableWrap .displayName {
  width: calc(100% / 19 * 3);
}

.tableWrap .email {
  width: calc(100% / 19 * 4);
  word-break: break-all;
}

.tableWrap .note {
  width: calc(100% / 19 * 5);
}

.icon {
  cursor: pointer;
}

.icon.disabled {
  pointer-events: none;
  visibility: hidden;
}

.pending {
  width: 76px;
  height: 30px;
  background-color: #1452CC;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font: normal normal 600 14px/18px InterLight;
  font-size: clamp(0.8rem, 0.2em + 0.5vw, 1rem);
}