.wrap {
  width: 1000px;
  height: 400px;
  background-color: #fff;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 40px 50px 0 50px;
  display: flex;
  flex-direction: column;
}

.modalTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font: normal normal bold 36px/60px InterSemiBold;
}

.title {
  font: normal normal 600 48px/59px InterBold;
}

.cancel {
  cursor: pointer;
  transform: scale(2);
}

.confirm {
  background-color: #175CE6;
  color: #fff;
  cursor: pointer;
  width: 240px;
  height: 60px;
  border-radius: 8px;
  border: none;
  font: normal normal normal 24px/29px InterRegular;
  align-self: center;
  margin-top: 56px;
  /* text-align: center; */
}

.confirm:active {
  background-color: #1142A6;
}

.confirm.disabled {
  background-color: #E3E3E3;
  cursor: not-allowed;
}

.linkInput {
  margin-top: 30px;
}

.content {
  margin-top: 30px;
  align-self: center;
  font: normal normal normal 18px/21px InterRegular;
}