.wrap {
  width: 940px;
  height: 640px;
  background-color: #fff;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 40px 50px 0 50px;
  display: flex;
  flex-direction: column;
}

.modalTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font: normal normal bold 36px/60px InterSemiBold;
}

.title {
  font: normal normal 600 24px/29px InterRegular;
}

.close {
  cursor: pointer;
  transform: scale(2);
}

.row {
  display: flex;
  align-items: center;
  margin-top: 20px;
  gap: 24px;
}

.courseName {
  flex: 3;
  background-color: #FFF;
  box-shadow: inset 0px 0px 6px #00000029;
  border-radius: 8px;
  height: 56px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-left: 20px;
  font: normal normal normal 16px/21px InterRegular;
}

.teacherSelect {
  flex: 1;
}

.content {
  height: 400px;
  margin-bottom: 20px;
  overflow-y: scroll;
}

.explanation {
  margin-top: 10px;
  font: normal normal normal 18px/21px InterRegular;
}

.hightlight {
  font: normal normal bold 18px/21px InterRegular;
}

.btnWrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* margin-top: 10px; */
  gap: 24px;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 144px;
  height: 48px;
  background-color: #FFFFFF;
  border-radius: 8px;
  cursor: pointer;
  font: normal normal normal 14px/17px InterRegular;
}

.remove {
  border: 2px solid #F10000;
  color: #F10000;
  margin-right: 5px;
}

.remove:hover {
  background-color: #F5052D;
  color: #fff;
}

.remove:active {
  border-color: #CC0022;
  background-color: #CC0022;
}

.cancel {
  border: 2px solid #707070;
  border-radius: 8px;
  color: #888888;
}

.cancel:active {
  border-color: #808080;
  background-color: #808080;
  color: #fff;
}

.cancel:hover {
  border-color: #B3B3B3;
  background-color: #B3B3B3;
  color: #fff;
}

.loadingWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #0000008A;
  z-index: 10;
}


