.wrap {
  width: 807px;
  background-color: #fff;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 40px 50px;
  display: flex;
  flex-direction: column;
}

.titleWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;
}

.title {
  font: normal normal 600 24px/33px InterRegular;
}

.field.hidden, .hidden {
  display: none;
}

.field {
  display: flex;
  align-items: center;
  width: 100%;
  height: 36px;
  margin-bottom: 36px;
}

.subTitle {
  width: 160px;
  font: normal normal 600 14px/17px InterRegular;
}

.input {
  height: 100%;
  width: 100%;
}

.btnWrap {
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: space-between;
  margin-top: 15px;
}

.btn {
  width: 144px;
  height: 48px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  font: normal normal normal 14px/17px InterRegular;
  background-color: #fff;
}

.save {
  border: 2px solid transparent;
  background-color: #1452CC;
  color: #fff;
}

.save:hover {
  background-color: #175CE6;
}

.save:active {
  background-color: #1142A6;
}

.save.disabled {
  background-color: #DCDEE0;
  pointer-events: none;
}

.cancel {
  border: 2px solid #B3B3B3;
  color: #000000;
}

.cancel:active {
  border-color: #808080;
  background-color: #808080;
  color: #fff;
}

.cancel:hover {
  border-color: #B3B3B3;
  background-color: #B3B3B3;
  color: #fff;
}

