.nav {
  width: 100%;
  height: 72px;
  box-sizing: border-box;
  padding-left: 30px;
  padding-right: 59px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font: normal normal 800 60px/75px InterMedium;
  font-size: clamp(1rem, 0.4rem + 0.5vw, 1.5rem);
}

.left img {
  width: 175px;
  height: 55px;
}

.right {
  display: flex;
  align-items: center;
}

.right div {
  margin-right: 21px;
}

.userProfile {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.identity {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 61px;
}

.identity_txt {
  color: #1452cc;
  font: normal normal normal 16px/21px InterMedium;
  font-size: clamp(0.8rem, 0.3rem + 0.45vw, 1.5rem);
}

.subtitle {
  font: normal normal normal 18px/21px InterMedium;
  font-size: clamp(1rem, 0.4rem + 0.5vw, 1.5rem);
  cursor: pointer;
}

.subitle.disabled {
  pointer-events: none;
}

.userPic {
  background-image: url('./user.png');
  background-position: center;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
}

.arrow {
  margin-top: 5px;
}

.logoWrap {
  margin-top: 30px;
  cursor: pointer;
}

.userProfile.disabled,
.menu.disabled {
  display: none;
}

.menu {
  display: none;
  margin-top: 20px;
  /* margin-bottom: 10px; */
}

.menuOption {
  padding: 10px 50px;
  cursor: pointer;
  font: normal normal normal 18px/21px InterMedium;
  font-size: clamp(1rem, 0.4rem + 0.5vw, 1.5rem);
}

.menuOption:hover {
  background-color: #f3f3f3;
}

.version {
  visibility: hidden;
  user-select: none;
}

.orgSelect {
  margin-left: 40px;
}

.orgSelect.hidden,
.orgSelect.disabled {
  display: none;
}

@media screen and (max-width: 1200px) {
  .menu {
    display: block;
    cursor: pointer;
    transform: scale(1.5);
    margin-right: 10px;
  }

  .menu.disabled {
    display: none;
  }
}
