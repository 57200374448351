.CalendarBlockWrap {
  flex: 2;
  background-color: #FFF;
  border-radius: 8px;
  height: 100%;
  box-sizing: border-box;
  padding: 36px;
}

.title {
  font: normal normal 600 24px/29px InterRegular;
  font-size: clamp(1.4rem, 0.4em + 1vw, 1.6rem);
}