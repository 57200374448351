.addClassBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  font: normal normal normal 24px/29px InterRegular;
  width: 180px;
  height: 60px;
  background-color: #175CE6;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  border: none;
}

.addClassBtn.disabled {
  display: none;
}

.addClassBtn:active {
  background-color: #1452CC;
}