.modalWrap {
    width: 732px;
    height: 285px;
    background-color: #fff;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 41px 47px 38px 47px;
    display: flex;
    flex-direction: column;
  }

  .titleWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .icon {
    cursor: pointer;
  }
  
  .title {
    /* margin-bottom: 21px; */
    font: normal normal 600 24px/60px InterRegular;
  }
  
  .update {
    background-color: #175CE6;
    color: #fff;
    cursor: pointer;
    width: 144px;
    height: 48px;
    border-radius: 8px;
    border: none;
    font: normal normal normal 14px/17px InterRegular;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    margin-top: 40px;
  }
  
  .update:active {
    background-color: #1142A6;
  }
  
  .update.disabled {
    background-color: #E3E3E3;
    /* cursor: not-allowed; */
    pointer-events: none;
  }