.manageUserWrap {
  background-color: #f5f8ff;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
}

.titleWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.titleWrap .title {
  font: normal normal 600 36px/44px InterMedium;
}

.slotWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 500px;
}

.slotItem {
  display: flex;
  align-items: center;
}

.slotItem .role {
  font: normal normal 600 16px/21px InterMedium;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
}

.slotItem .slot {
  color: #1452cc;
  font: normal normal normal 16px/21px InterMedium;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
}

.slotItem .slot.warning {
  color: #ff0000;
}

.utilityRowWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 24px;
}

.utilityRowWrap .right {
  display: flex;
  gap: 24px;
}

.searchInputWrap {
  width: 323px;
}

.addMemberBtn {
  width: 181px;
  height: 60px;
  background-color: #175ce6;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
  border-radius: 8px;
  font: normal normal 600 18px/21px InterLight;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
  border: none;
}

.addMemberBtn:active {
  background-color: #1452cc;
}

.tableContainer {
  /* border: 1px solid royalblue; */
  box-sizing: border-box;
  height: calc(100vh - 23px);
  overflow-x: scroll;
  /* overflow-y: visible; */
}

.tableWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  gap: 20px;
  width: 100%;
  min-width: 1350px;
}

.tableItem {
  border-radius: 8px;
  height: 100%;
  background-color: #fff;
  font: normal normal 600 16px/21px InterMedium;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-left: 0.8%;
}

.tableItem.lightText {
  font: normal normal 600 16px/21px InterLight;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
}

.tableItem.disabled {
  color: #e60026;
}

.tableItem.enabled {
  color: #009135;
}

.tableItem.notallowed {
  color: #888888;
}

.tableItem.lightText.highlight {
  color: #1452cc;
}

.tableItem.center {
  padding: 0;
  justify-content: center;
}

.tableItem.observationMode {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.tableWrap.visible .checkbox {
  width: calc(100% / 23);
  max-width: 48px;
  min-width: 48px;
}

.tableWrap.visible .role,
.tableWrap.visible .status,
.tableWrap.visible .action {
  width: calc(100% / 23 * 2);
}

.tableWrap.visible .name,
.tableWrap.visible .displayName,
.tableWrap.visible .createFrom,
.tableWrap.visible .observationMode {
  width: calc(100% / 23 * 3);
  word-break: break-all;
}

.tableWrap.visible .tableItem.email {
  width: calc(100% / 23 * 4);
}

.tableWrap .checkbox {
  width: calc(100% / 20);
  max-width: 48px;
  min-width: 48px;
}

.tableWrap .role,
.tableWrap .status,
.tableWrap .action {
  width: calc(100% / 20 * 2);
}

.tableWrap .name,
.tableWrap .displayName,
.tableWrap .createFrom,
.tableWrap .observationMode {
  width: calc(100% / 20 * 3);
  word-break: break-all;
}

.tableWrap .email {
  width: calc(100% / 20 * 4);
}

.email {
  word-break: break-all;
}

.action.more {
  cursor: pointer;
}

.action.invisible {
  pointer-events: none;
}

.action.hide {
  pointer-events: none;
  visibility: hidden;
}

.paginationWrap {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.deleteRowWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 21px;
  margin-bottom: 27px;
  height: 60px;
}

.deleteRowWrap .left {
  display: flex;
  align-items: center;
}

.deleteRowWrap .right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.deleteRowWrap .rightTop {
  display: flex;
  align-items: center;
  gap: 12px;
}

.deleteRowWrap .left .selected {
  font: normal normal 600 24px/30px InterLight;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
}

.deleteRowWrap .left .cancel {
  margin-left: 32px;
  font: normal normal 600 24px/30px InterLight;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
  color: #1452cc;
  cursor: pointer;
}

.deleteRowWrap .removeBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 182px;
  height: 60px;
  gap: 22px;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  cursor: pointer;
}

.deleteRowWrap .removeBtn .removeText {
  font: normal normal 600 24px/30px InterLight;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
  color: #ff0000;
}

.deleteRowWrap .removeBtn .removeIcon {
  transform: scale(1.2);
}

.errorWrap {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  background-color: #fff;
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font: normal normal normal 24px/29px InterMedium;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
  color: #bebebe;
}

.refreshIconWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  color: #4668ff;
  font: normal normal normal 16px/24px InterMedium;
  font-size: clamp(1rem, 0.4em + 0.5vw, 1.2rem);
  cursor: pointer;
}

.menuOption {
  width: 169px;
  height: 48px;
  cursor: pointer;
  font: normal normal 600 14px/17px InterRegular;
  font-size: clamp(0.8rem, 0.3rem + 0.4vw, 1.3rem);
  display: flex;
  align-items: center;
  padding-left: 12px;
}

.menuOption:hover {
  background-color: #f3f3f3;
  color: #1452cc;
}

.pending {
  width: 76px;
  height: 30px;
  background-color: #1452cc;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  box-sizing: border-box;
  font: normal normal 600 14px/18px InterLight;
  font-size: clamp(0.8rem, 0.2em + 0.5vw, 1rem);
}

.loadingWrap {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;
}

.bottomRow {
  display: flex;
  justify-content: flex-end;
  /* justify-content: space-between; */
  align-items: center;
  margin-top: 30px;
}

.switch {
  display: flex;
  align-items: center;
  gap: 22px;
}

.warning {
  font: normal normal normal 14px/20px InterRegular;
  color: #e60026;
}
